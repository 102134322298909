import { Box, Divider, Stack, Typography } from "@mui/material";
import { DownloadAllUserIssuedInvoicesButton } from "Components/Settings/Invoices/DownloadAllUserIssuedInvoicesButton";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlDefaultButton } from "Components/Shared/Buttons/BlDefaultButton";
import { BlDataTable } from "Components/Shared/DataTable/BlDataTable";
import { ContractIcon, ListLayoutIcon } from "Components/Shared/Icons";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { getInvoicePdfAsync } from "State/Invoice/GetInvoicePdf";
import { getInvoiceXmlAsync } from "State/Invoice/GetInvoiceXml";
import { getUserIssuedInvoicesAsync } from "State/Invoice/GetUserIssuedInvoicesSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { formatCurrency } from "Utils/CurrencyUtils";
import { formatDate } from "Utils/DateUtils";
import { formatCompanyNumbers } from "Utils/PartyUtils";
import { useEffect, useState } from "react";
import { styled } from "styled-components";

const StyledValue = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const UserIssuedInvoicesList: React.FunctionComponent = _ => {
  const { t } = useResource();
  const dispatch = useAppDispatch();
  const codeList = useAppSelector(state => state.codeList.collection.data);

  const [filter, setFilter] = useState({
    limit: 25,
    offset: 0,
  });

  useEffect(() => {
    dispatch(
      getUserIssuedInvoicesAsync.request({
        ...filter,
      }),
    );
  }, [dispatch, filter]);

  const onPageChange = (page: number, rows: number) => {
    setFilter({ ...filter, offset: page * rows, limit: rows });
  };

  const { data, isLoading } = useAppSelector(
    state => state.invoice.userIssuedInvoices,
  );
  const pdfState = useAppSelector(state => state.invoice.pdf);
  const xmlState = useAppSelector(state => state.invoice.xml);

  return (
    <>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Box maxWidth={"80%"}>
          <StyledFlex $gap={0.5} $alignItems="center">
            <Typography variant="h2">
              {t(Resources.Settings.Invoices.InvoicesList.IssuedTitle)}
            </Typography>

            <BlDefaultTooltip
              title={t(Resources.Settings.Invoices.InvoicesList.IssuedTooltip)}
            />
          </StyledFlex>

          <Typography variant="subtitle2" fontStyle={"normal"}>
            {t(Resources.Settings.Invoices.InvoicesList.IssuedDescription)}
          </Typography>
        </Box>

        <Box display={"flex"} alignItems={"flex-end"}>
          <DownloadAllUserIssuedInvoicesButton isDisabled={!data?.totalCount} />
        </Box>
      </Stack>

      <Divider sx={{ marginTop: 3, marginBottom: 2 }} />

      <BlDataTable
        isLoading={isLoading}
        totalCount={data?.totalCount}
        rows={data?.items.map(e => ({ data: e })) ?? []}
        rowEntityID="publicID"
        getHeaderCell={column => {
          return <div>{column.label ?? column.id}</div>;
        }}
        onPageChange={onPageChange}
        skeletonRowsCount={5}
        columns={[
          {
            id: "number",
            label: t(Resources.Settings.Invoices.InvoicesList.Number),
          },
          {
            id: "customerFormattedName",
            label: t(
              Resources.Settings.Invoices.InvoicesList.CustomerFormattedName,
            ),
          },
          {
            id: "invoiceDate",
            label: t(Resources.Settings.Invoices.InvoicesList.InvoiceDate),
          },
          {
            id: "priceWithoutTax",
            label: t(Resources.Settings.Invoices.InvoicesList.PriceWithoutTax),
          },
          {
            id: "priceWithTax",
            label: t(Resources.Settings.Invoices.InvoicesList.PriceWithTax),
          },
          { id: "action", label: "", maxWidth: 0, justifySelf: "flex-end" },
        ]}
        getCell={(column, row) => {
          switch (column) {
            case "number":
              return <Typography fontWeight={600}>{row.number}</Typography>;

            case "customerFormattedName":
              return (
                <Stack>
                  <StyledValue>{row.customerFormattedName}</StyledValue>

                  <StyledValue>
                    {formatCompanyNumbers(
                      row.customerCompanyNumber,
                      row.customerTaxNumber,
                    )}
                  </StyledValue>
                </Stack>
              );

            case "invoiceDate":
              return <StyledValue>{formatDate(row.invoiceDate)}</StyledValue>;

            case "priceWithTax":
              return (
                <StyledValue>
                  {formatCurrency(
                    row.priceWithTax,
                    codeList?.currencies.find(c => c.id === row.currencyID)
                      ?.code,
                  )}
                </StyledValue>
              );

            case "priceWithoutTax":
              return (
                <StyledValue>
                  {formatCurrency(
                    row.priceWithoutTax,
                    codeList?.currencies.find(c => c.id === row.currencyID)
                      ?.code,
                  )}
                </StyledValue>
              );

            case "action":
              return (
                <StyledFlex $alignItems={"center"} $gap={2.5}>
                  <BlDefaultButton
                    title={t(Resources.Settings.Invoices.InvoicesList.Pdf)}
                    isLoading={
                      pdfState.isLoading &&
                      pdfState.invoicePublicID === row.publicID
                    }
                    onClick={() =>
                      dispatch(
                        getInvoicePdfAsync.request({
                          invoicePublicID: row.publicID,
                          invoiceNumber: row.number,
                        }),
                      )
                    }
                  >
                    <ContractIcon />
                  </BlDefaultButton>

                  <BlDefaultButton
                    title={t(Resources.Settings.Invoices.InvoicesList.ISDOC)}
                    isLoading={
                      xmlState.isLoading &&
                      xmlState.invoicePublicID === row.publicID
                    }
                    onClick={() =>
                      dispatch(
                        getInvoiceXmlAsync.request({
                          invoicePublicID: row.publicID,
                          invoiceNumber: row.number,
                        }),
                      )
                    }
                  >
                    <ListLayoutIcon />
                  </BlDefaultButton>

                  <div></div>
                </StyledFlex>
              );

            default:
              const value = (row as any)[column as any] ?? "";

              return <div>{value}</div>;
          }
        }}
      />
    </>
  );
};
