import * as React from "react";
export const BookIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={20}
      height={20}
      {...props}
    >
      <path
        d="M 4.5 7 A 1.50015 1.50015 0 0 0 3 8.5 L 3 23 L 3 37.5 A 1.50015 1.50015 0 0 0 4.5 39 L 20 39 C 21.398226 39 22.5 40.101774 22.5 41.5 A 1.50015 1.50015 0 0 0 25.5 41.5 C 25.5 40.101774 26.601774 39 28 39 L 43.5 39 A 1.50015 1.50015 0 0 0 45 37.5 L 45 23 L 45 8.5 A 1.50015 1.50015 0 0 0 43.5 7 L 28 7 C 26.415161 7 25.007083 7.7052607 24 8.7910156 C 22.992917 7.7052607 21.584839 7 20 7 L 4.5 7 z M 6 10 L 20 10 C 21.398226 10 22.5 11.101774 22.5 12.5 L 22.5 36.683594 C 21.73905 36.283492 20.91337 36 20 36 L 6 36 L 6 23 L 6 10 z M 28 10 L 42 10 L 42 23 L 42 36 L 28 36 C 27.08663 36 26.26095 36.283492 25.5 36.683594 L 25.5 12.519531 A 1.50015 1.50015 0 0 0 25.5 12.5 C 25.5 11.101774 26.601774 10 28 10 z M 37.486328 12.978516 A 1.50015 1.50015 0 0 0 36.439453 13.439453 L 34 15.878906 L 31.560547 13.439453 A 1.50015 1.50015 0 0 0 30.484375 12.984375 A 1.50015 1.50015 0 0 0 29.439453 15.560547 L 31.878906 18 L 29.439453 20.439453 A 1.50015 1.50015 0 1 0 31.560547 22.560547 L 34 20.121094 L 36.439453 22.560547 A 1.50015 1.50015 0 1 0 38.560547 20.439453 L 36.121094 18 L 38.560547 15.560547 A 1.50015 1.50015 0 0 0 37.486328 12.978516 z M 18.470703 13.986328 A 1.50015 1.50015 0 0 0 17.439453 14.439453 L 12.5 19.378906 L 10.560547 17.439453 A 1.50015 1.50015 0 1 0 8.4394531 19.560547 L 11.439453 22.560547 A 1.50015 1.50015 0 0 0 13.560547 22.560547 L 19.560547 16.560547 A 1.50015 1.50015 0 0 0 18.470703 13.986328 z M 9.5 25 A 1.50015 1.50015 0 1 0 9.5 28 L 18.5 28 A 1.50015 1.50015 0 1 0 18.5 25 L 9.5 25 z M 29.5 25 A 1.50015 1.50015 0 1 0 29.5 28 L 38.5 28 A 1.50015 1.50015 0 1 0 38.5 25 L 29.5 25 z M 9.5 30 A 1.50015 1.50015 0 1 0 9.5 33 L 18.5 33 A 1.50015 1.50015 0 1 0 18.5 30 L 9.5 30 z M 29.5 30 A 1.50015 1.50015 0 1 0 29.5 33 L 38.5 33 A 1.50015 1.50015 0 1 0 38.5 30 L 29.5 30 z"
        fill="currentColor"
      />
    </svg>
  );
};
