import {
  OrderDto,
  UserSupplierCompanyListItemDto,
  PartyDto,
  EnterpriseServiceListItemDto,
  OrderServiceDto,
  OrderPeriodicity,
  OrderPaymentFrequency,
  ServiceVariantFrequency,
  OrderClientStatus,
  PartyType,
  OrderServiceRate,
  OrderFrequency,
  ServiceType,
  SaveDraftRequest,
  AddressType,
  AddressDto,
  SaveDraftOrderService,
  ServicePackageListItemDto,
  OrderPaymentType,
  CompanyDto,
} from "Api/Api";
import {
  FormMode,
  OrderFormModel,
  ServiceFormModel,
  ServicePackageType,
} from "Components/Orders/Draft/StepFormValidationSchema";
import { PartyFormModel } from "Components/Shared/Party/PartyFormValidationSchema";
import { CurrencyCode } from "Models/CurrencyCodes";
import { toLocalTimeZoneString } from "Utils/DateUtils";

export function getDefaultValues(
  order: OrderDto | null,
  companies: UserSupplierCompanyListItemDto[],
  clientParty: PartyDto | null,
  enterpriseServices: EnterpriseServiceListItemDto[],
  orderServices: OrderServiceDto[],
  servicePackages: ServicePackageListItemDto[],
) {
  const paymentPeriodicity = !order
    ? OrderPeriodicity.Single
    : order.paymentFrequency !== OrderPaymentFrequency.Single
      ? OrderPeriodicity.Periodic
      : OrderPeriodicity.Single;

  const frequency =
    paymentPeriodicity === OrderPeriodicity.Single
      ? ServiceVariantFrequency.Single
      : ServiceVariantFrequency.Monthly;

  const servicePackageType = orderServices.some(os =>
    servicePackages.some(sp => sp.serviceID === os.serviceID),
  )
    ? ServicePackageType.Package
    : ServicePackageType.ProvidedService;

  const supplierCompanyID =
    companies.find(
      x =>
        x.companyID === order?.supplierCompanyID &&
        x.canBeSelectedToOrderDisabled === false,
    )?.companyID ??
    companies.find(x => x.isDefault)?.companyID ??
    companies.find(x => x.canBeSelectedToOrderDisabled === false)?.companyID;

  return {
    orderID: order?.orderID ?? 0,
    hourRateServiceVariantID: order?.hourRateServiceVariantID,
    mode: FormMode.Draft,
    supplierCompanyID: supplierCompanyID,
    clientID: order?.clientID ?? 0,
    note: order?.note ?? "",
    isClientElectronicCommunicationConsent:
      order?.isClientElectronicCommunicationConsent,
    isClientPersonalDataProcessingConsent:
      order?.isClientPersonalDataProcessingConsent,
    clientIdentification: clientParty?.identification,
    clientIdentificationNumber: clientParty?.identificationNumber,
    party: {
      phone: clientParty?.phone ?? "+420",
      status: order?.clientStatus ?? OrderClientStatus.Existing,
      type: clientParty?.type ?? PartyType.NaturalPerson,
      companyNumber: clientParty?.companyNumber ?? "",
      firstName: clientParty?.firstName ?? "",
      lastName: clientParty?.lastName ?? "",
      companyName: clientParty?.companyName ?? "",
      personalNumber: clientParty?.personalNumber ?? "",
      birthDate: clientParty?.birthDate ?? undefined,
      isForeigner: clientParty?.isForeigner ?? false,
      email: clientParty?.email ?? "",
      street: clientParty?.addresses[0]?.street ?? "",
      streetNumber: clientParty?.addresses[0]?.streetNumber ?? "",
      municipality: clientParty?.addresses[0]?.municipality ?? "",
      postalCode: clientParty?.addresses[0]?.postalCode ?? "",
      orientationNumber: clientParty?.addresses[0]?.orientationNumber ?? "",
      taxNumber: clientParty?.taxNumber ?? "",
      isVATPayer: clientParty?.isVATPayer ?? false,
      companyRepresentativeBirthDate:
        clientParty?.companyRepresentativeBirthDate ?? undefined,
      companyRepresentativeFirstName:
        clientParty?.companyRepresentativeFirstName ?? "",
      companyRepresentativeLastName:
        clientParty?.companyRepresentativeLastName ?? "",
    } as PartyFormModel["party"],
    serviceRate: order?.serviceRate ?? OrderServiceRate.FixedPrice,
    services:
      servicePackageType === ServicePackageType.Package
        ? fillServicesFromServicePackages(
            servicePackages,
            frequency,
            orderServices,
          )
        : fillServices(enterpriseServices, frequency, orderServices),
    order: {
      servicePackageType: servicePackageType,
      periodicity: order?.periodicity ?? OrderPeriodicity.Single,
      paymentType: order?.paymentType ?? OrderPaymentType.Prepaid,
      frequency: order?.frequency ?? OrderFrequency.Weekly,
      isIndefiniteEnd: order?.isIndefiniteEnd ?? true,
      paymentPeriodicity: paymentPeriodicity,
      paymentFrequency: order?.paymentFrequency ?? OrderPaymentFrequency.Single,
      paymentFrequencyDateFrom: !!order?.paymentFrequencyDateFrom
        ? new Date(order?.paymentFrequencyDateFrom)
        : new Date(),
      paymentFrequencyDateTo: !!order?.paymentFrequencyDateTo
        ? new Date(order?.paymentFrequencyDateTo)
        : new Date(),
    },
  } as OrderFormModel;
}

export function fillServices(
  enterpriseServices: EnterpriseServiceListItemDto[],
  frequency: ServiceVariantFrequency,
  orderServices: OrderServiceDto[],
): ServiceFormModel[] {
  return enterpriseServices
    .filter(
      es =>
        es.isEnabled &&
        es.type === ServiceType.ProvidedService &&
        es.variants.some(x => x.isEnabled && x.frequency === frequency),
    )
    .map(es => {
      const orderService = orderServices.find(
        x => x.serviceID === es.serviceID,
      );

      const fallbackVariant = es.variants.find(
        x => x.isEnabled && x.frequency === frequency,
      )!;

      const serviceVariant = !!orderService?.serviceVariantID
        ? es.variants.find(
            x => x.serviceVariantID === orderService?.serviceVariantID,
          ) ?? fallbackVariant
        : fallbackVariant;

      const formModel: ServiceFormModel = {
        isSelected: !!orderService,
        hoursSpent: orderService?.hoursSpent ?? 0,
        priceWithoutTax:
          orderService?.priceWithoutTax ?? serviceVariant.priceWithoutTax ?? 0,
        priceWithTax: !!orderService?.priceWithTax
          ? orderService?.priceWithoutTax * (1 + (serviceVariant?.taxRate ?? 0))
          : serviceVariant.priceWithTax ?? 0,
        taxRate: serviceVariant.taxRate ?? 0,
        originalPriceWithoutTax: serviceVariant.priceWithoutTax ?? 0,
        originalPriceWithTax: serviceVariant.priceWithTax ?? 0,
        serviceID: es.serviceID,
        serviceVariantID: serviceVariant.serviceVariantID ?? 0,
        currencyCode: serviceVariant.currencyCode ?? CurrencyCode.CZK,
        serviceDescription: es.description ?? "",
        serviceName: es.name ?? "",
        serviceVariantProduct: serviceVariant.product,
        serviceVariantName: serviceVariant.name ?? "",
        isEditing: false,
      };

      return formModel;
    });
}

export function fillServicesFromServicePackages(
  servicePackages: ServicePackageListItemDto[],
  frequency: ServiceVariantFrequency,
  orderServices: OrderServiceDto[],
): ServiceFormModel[] {
  return servicePackages
    .filter(sp => sp.variants!.some(x => x.frequency === frequency))
    .map(sp => {
      const orderService = orderServices.find(
        x => x.serviceID === sp.serviceID,
      );

      const fallbackVariant = sp.variants!.find(
        x => x.frequency === frequency,
      )!;

      const serviceVariant = !!orderService?.serviceVariantID
        ? sp.variants!.find(
            x => x.serviceVariantID === orderService?.serviceVariantID,
          ) ?? fallbackVariant
        : fallbackVariant;

      const description = `${sp.description};${sp.relatedServices
        ?.map(x => x.name)
        .join(", ")}`;

      const formModel: ServiceFormModel = {
        isSelected: !!orderService,
        hoursSpent: orderService?.hoursSpent ?? 0,
        priceWithoutTax: serviceVariant.priceWithoutTax ?? 0,
        priceWithTax: serviceVariant.priceWithTax ?? 0,
        taxRate: serviceVariant.taxRate ?? 0,
        originalPriceWithoutTax: serviceVariant.priceWithoutTax ?? 0,
        originalPriceWithTax: serviceVariant.priceWithTax ?? 0,
        serviceID: sp.serviceID,
        serviceVariantID: serviceVariant.serviceVariantID ?? 0,
        currencyCode: serviceVariant.currencyCode ?? CurrencyCode.CZK,
        serviceDescription: description,
        serviceName: sp.name ?? "",
        serviceVariantName: serviceVariant.name ?? "",
        serviceVariantProduct: serviceVariant.product,
        isEditing: false,
      };

      return formModel;
    });
}

export function map(
  formModel: OrderFormModel,
  enterpriseServices: EnterpriseServiceListItemDto[],
  userCompany: CompanyDto,
) {
  const request: SaveDraftRequest = {
    orderID: formModel.orderID,
    supplierCompanyID: formModel.supplierCompanyID,
    frequency: formModel.order.frequency,
    periodicity: formModel.order.periodicity,
    isClientElectronicCommunicationConsent:
      formModel.isClientElectronicCommunicationConsent,
    isClientPersonalDataProcessingConsent:
      formModel.isClientPersonalDataProcessingConsent,
    paymentFrequency: formModel.order.paymentFrequency,
    isIndefiniteEnd: formModel.order.isIndefiniteEnd,
    paymentType: formModel.order.paymentType,
    paymentFrequencyDateFrom: !!formModel.order.paymentFrequencyDateFrom
      ? toLocalTimeZoneString(formModel.order.paymentFrequencyDateFrom)
      : null,
    paymentFrequencyDateTo: !!formModel.order.paymentFrequencyDateTo
      ? toLocalTimeZoneString(formModel.order.paymentFrequencyDateTo)
      : null,
    clientParty: {
      type: formModel.party.type,
      firstName: formModel.party.firstName,
      lastName: formModel.party.lastName,
      companyName: formModel.party.companyName,
      companyNumber: formModel.party.companyNumber,
      personalNumber: formModel.party.personalNumber,
      birthDate: !!formModel.party.birthDate
        ? toLocalTimeZoneString(formModel.party.birthDate)
        : null,
      isForeigner: formModel.party.isForeigner ?? false,
      email: formModel.party.email,
      phone: formModel.party.phone,
      taxNumber: formModel.party.taxNumber,
      isVATPayer: formModel.party.isVATPayer,
      identification: formModel.clientIdentification,
      identificationNumber: formModel.clientIdentificationNumber,
      companyRepresentativeBirthDate: !!formModel.party
        .companyRepresentativeBirthDate
        ? toLocalTimeZoneString(formModel.party.companyRepresentativeBirthDate)
        : null,
      companyRepresentativeFirstName:
        formModel.party.companyRepresentativeFirstName,
      companyRepresentativeLastName:
        formModel.party.companyRepresentativeLastName,
      addresses: [
        {
          municipality: formModel.party.municipality ?? "",
          street: formModel.party.street ?? "",
          postalCode: formModel.party.postalCode ?? "",
          type: AddressType.Permanent,
          orientationNumber: formModel.party.orientationNumber ?? "",
          streetNumber: formModel.party.streetNumber ?? "",
          addressID: 0,
        } as AddressDto,
      ],
    },
    serviceRate: formModel.serviceRate,
    hourRateServiceVariantID: formModel.hourRateServiceVariantID,
    note: formModel.note,
    clientID: formModel.clientID,
    clientStatus: formModel.party.status,
    orderServices: formModel.services
      .filter(x => x.isSelected)
      .map(x => {
        const hoursSpent =
          formModel.serviceRate === OrderServiceRate.HourRate
            ? Number(x.hoursSpent)
            : null;

        const hourRateService =
          formModel.serviceRate === OrderServiceRate.HourRate
            ? enterpriseServices.find(x => x.type === ServiceType.HourRate)
            : null;

        const hourRateServiceVariant = hourRateService?.variants.find(
          x =>
            x.serviceVariantID === Number(formModel.hourRateServiceVariantID),
        );

        const isVatUsed = userCompany.party.isVATPayer ?? false;

        return {
          hoursSpent,
          priceWithoutTax:
            formModel.serviceRate === OrderServiceRate.FixedPrice
              ? x.priceWithoutTax
              : (hoursSpent ?? 0) * hourRateServiceVariant!.priceWithoutTax!,
          priceWithTax:
            formModel.serviceRate === OrderServiceRate.FixedPrice
              ? isVatUsed
                ? x.priceWithTax
                : x.priceWithoutTax
              : (hoursSpent ?? 0) *
                (isVatUsed
                  ? hourRateServiceVariant!.priceWithTax!
                  : hourRateServiceVariant!.priceWithoutTax!),
          taxRate: isVatUsed ? hourRateServiceVariant?.taxRate ?? x.taxRate : 0,
          serviceID: x.serviceID,
          serviceDescription: x.serviceDescription.includes(";")
            ? x.serviceDescription.split(";")[0].trim()
            : x.serviceDescription,
          serviceName: x.serviceName,
          serviceVariantID: x.serviceVariantID,
          serviceVariantName: x.serviceVariantName,
          serviceVariantProduct: x.serviceVariantProduct,
          serviceVariantTaxRate: x.taxRate,
          currencyCode: x.currencyCode,
          serviceType:
            formModel.order.servicePackageType === ServicePackageType.Package
              ? ServiceType.Package
              : formModel.serviceRate === OrderServiceRate.HourRate
                ? ServiceType.HourRate
                : ServiceType.ProvidedService,
        } as SaveDraftOrderService;
      }),
  };

  return request;
}
