import {
  EntityListOfUserReceivedInvoiceListItemDto,
  getInvoicesUserReceivedInvoices,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getUserReceivedInvoicesAsync = createAsyncAction(
  "@invoice/GET_LIST_RECEIVED_REQUEST",
  "@invoice/GET_LIST_RECEIVED_RESPONSE",
  "@invoice/GET_LIST_RECEIVED_FAILURE",
)<
  { offset: number; limit: number },
  EntityListOfUserReceivedInvoiceListItemDto,
  Error
>();

function* getUserReceivedInvoices(
  action: ReturnType<typeof getUserReceivedInvoicesAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getInvoicesUserReceivedInvoices,
      action.payload.offset,
      action.payload.limit,
    );

    if (status === 200) {
      yield put(getUserReceivedInvoicesAsync.success(data));
      return;
    }

    yield put(
      getUserReceivedInvoicesAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(getUserReceivedInvoicesAsync.failure(err as Error));
  }
}
export function* getUserReceivedInvoicesSaga() {
  yield takeLatest(
    getType(getUserReceivedInvoicesAsync.request),
    getUserReceivedInvoices,
  );
}

export const getUserReceivedInvoicesSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EntityListOfUserReceivedInvoiceListItemDto>(
      false,
    ),
  name: "@invoice/getUserReceivedInvoices",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EntityListOfUserReceivedInvoiceListItemDto>(
        false,
      ),
  },
  extraReducers: builder => {
    handleRequestCase(getUserReceivedInvoicesAsync, builder);
    handleSuccessCase(getUserReceivedInvoicesAsync, builder);
    handleFailureCase(getUserReceivedInvoicesAsync, builder);
  },
});

export const { resetSlice } = getUserReceivedInvoicesSlice.actions;
