import {
  EntityListOfUserIssuedInvoiceListItemDto,
  getInvoicesUserIssuedInvoices,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getUserIssuedInvoicesAsync = createAsyncAction(
  "@invoice/GET_LIST_ISSUED_REQUEST",
  "@invoice/GET_LIST_ISSUED_RESPONSE",
  "@invoice/GET_LIST_ISSUED_FAILURE",
)<
  { offset: number; limit: number },
  EntityListOfUserIssuedInvoiceListItemDto,
  Error
>();

function* getUserIssuedInvoices(
  action: ReturnType<typeof getUserIssuedInvoicesAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getInvoicesUserIssuedInvoices,
      action.payload.offset,
      action.payload.limit,
    );

    if (status === 200) {
      yield put(getUserIssuedInvoicesAsync.success(data));
      return;
    }

    yield put(
      getUserIssuedInvoicesAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(getUserIssuedInvoicesAsync.failure(err as Error));
  }
}
export function* getUserIssuedInvoicesSaga() {
  yield takeLatest(
    getType(getUserIssuedInvoicesAsync.request),
    getUserIssuedInvoices,
  );
}

export const getUserIssuedInvoicesSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EntityListOfUserIssuedInvoiceListItemDto>(false),
  name: "@invoice/getUserIssuedInvoices",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EntityListOfUserIssuedInvoiceListItemDto>(
        false,
      ),
  },
  extraReducers: builder => {
    handleRequestCase(getUserIssuedInvoicesAsync, builder);
    handleSuccessCase(getUserIssuedInvoicesAsync, builder);
    handleFailureCase(getUserIssuedInvoicesAsync, builder);
  },
});

export const { resetSlice } = getUserIssuedInvoicesSlice.actions;
