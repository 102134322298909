import { Box, Divider, Grid, Typography } from "@mui/material";
import {
  EnterpriseServiceListItemDto,
  EnterpriseServiceVariantUpdateListItemDto,
  ServiceType,
  ServiceVariantFrequency,
} from "Api/Api";
import { EucsDisableService } from "Components/Settings/Services/Eucs/EucsDisableService";
import { ServiceVariant } from "Components/Settings/Services/ServiceVariant";
import { ServicesFormModel } from "Components/Settings/Services/ServicesForms";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { StyledValidationText } from "Components/Shared/FormStyles";
import HtmlContent from "Components/Shared/HtmlContent";
import { FlagIcon } from "Components/Shared/Icons";
import { BlCheckboxInput } from "Components/Shared/Inputs/Form/BlCheckboxInput";
import { BlRadioButtons } from "Components/Shared/Inputs/Form/BlRadioButtons";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { PriceTaxType } from "Constants/PriceTaxType";
import useScrollToError from "Hooks/Form/useScrollToError";
import { Resources, useResource } from "Translations/Resources";
import { enumToCodeList } from "Utils/EnumUtils";
import { PRICE_TAX_TYPE_STORAGE_KEY } from "Utils/LocalStorageUtils";
import { get } from "lodash-es";
import { FieldError, UseFormReturn } from "react-hook-form";
import styled, { useTheme } from "styled-components";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { useEffect, useState } from "react";

export const StyledService = styled.div`
  display: flex;
  align-items: flex-start;
  padding: ${props => props.theme.spacing(1.5)};
  background-color: ${props => props.theme.colors.fieldBackground};
  border-radius: ${props => props.theme.borderRadius};
  margin-bottom: ${props => props.theme.spacing(2)};

  .checkbox-wrapper {
    margin-right: ${props => props.theme.spacing(1)};
  }
`;

type Props = {
  form: UseFormReturn<ServicesFormModel>;
  services: EnterpriseServiceListItemDto[];
};

const StyledPriceTaxTypRadioWrapper = styled.div``;

const WrapperMethodicInstructions = styled.div`
  border-radius: 12px;
  border: 1px solid;
  border-color: ${props => props.theme.palette.primary.main};
  padding: ${props => props.theme.spacing(2)};
  margin-bottom: ${props => props.theme.spacing(1)};
  margin-top: ${props => props.theme.spacing(3)};
`;

const SafeHTML: React.FC<{ html: string }> = ({ html }) => (
  <span dangerouslySetInnerHTML={{ __html: html }} />
);

export const ProvidedServices: React.FunctionComponent<Props> = props => {
  const { t } = useResource();
  const { form, services } = props;
  const {
    control,
    formState: { errors },
  } = form;
  const formServices = form.watch("services");
  const theme = useTheme();
  const [buttonVisibility, setButtonVisibility] = useState<boolean[]>([]);

  useEffect(() => {
    if (formServices?.length > 0) {
      setButtonVisibility(formServices.map(() => true));
    }
  }, [formServices]);

  const handleButtonClick = (index: number) => {
    setButtonVisibility(current =>
      current.map((visibility, i) => (i === index ? false : visibility)),
    );
  };

  const getFrequency = (service: EnterpriseServiceVariantUpdateListItemDto) => {
    return services
      .map(s =>
        s.variants.find(
          v =>
            v.serviceSettingServiceVariantID ===
            service.serviceSettingServiceVariantID,
        ),
      )
      .filter(x => !!x)[0]?.frequency;
  };
  useScrollToError(errors);

  return (
    <>
      <StyledFlex $alignItems="center" $gap={0.5}>
        <Typography variant="h2">
          {t(Resources.Settings.Services.ProvidedServices.Title)}
        </Typography>
        <BlDefaultTooltip
          title={t(Resources.Settings.Services.ProvidedServices.Tooltip)}
        />
      </StyledFlex>

      <StyledFlex $gap={1}>
        <Typography variant="subtitle2" fontStyle={"normal"} marginBottom={2}>
          {t(Resources.Settings.Services.ProvidedServices.Description)}
        </Typography>
      </StyledFlex>
      <WrapperMethodicInstructions style={{ marginTop: 0 }}>
        <StyledFlex $gap={1}>
          <FlagIcon color={theme.palette.primary.main} />

          <Typography
            color={theme => theme.palette.primary.main}
            fontWeight={700}
            fontSize={18}
            marginBottom={1}
          >
            {t(Resources.Settings.Services.MethodicalInstructionsTitle)}
          </Typography>
        </StyledFlex>
        <StyledFlex $gap={1}>
          <Typography>
            <SafeHTML
              html={t(
                Resources.Settings.Services
                  .MethodicalInstructionsIntroductionDescription,
              )}
            />
          </Typography>
        </StyledFlex>
      </WrapperMethodicInstructions>
      <StyledFlex>
        <StyledPriceTaxTypRadioWrapper>
          <BlRadioButtons
            control={form.control}
            errors={errors}
            name={"priceTaxType"}
            onChange={value => {
              localStorage.setItem(PRICE_TAX_TYPE_STORAGE_KEY, value);
            }}
            labelComponent={
              <>
                <Typography marginRight={1}>
                  {t(
                    Resources.Settings.ServicePackages.Detail.Form.PriceTaxType
                      .Label,
                  )}
                </Typography>
                <BlDefaultTooltip
                  title={t(
                    Resources.Settings.ServicePackages.Detail.Form.PriceTaxType
                      .Tooltip,
                  )}
                />
              </>
            }
            codeList={enumToCodeList(
              PriceTaxType,
              Resources.Transactions.PriceTaxTypeName,
            )}
          />
        </StyledPriceTaxTypRadioWrapper>
      </StyledFlex>

      <EucsDisableService></EucsDisableService>
      {formServices?.map((formService, serviceIndex) => {
        const service = services.find(
          y => y.serviceSettingID === formService.serviceSettingID,
        );

        if (!service || service.type === ServiceType.HourRate) {
          return null;
        }

        const fieldError = get(
          form.formState.errors,
          `services.${serviceIndex}.variants`,
        ) as unknown as FieldError;

        return (
          <StyledService key={formService.serviceSettingID}>
            <div className="checkbox-wrapper">
              <BlCheckboxInput
                control={control}
                errors={errors}
                name={`services.${serviceIndex}.isEnabled`}
              />
            </div>

            <div>
              <Typography fontSize={16} fontWeight={700}>
                {service.name}
              </Typography>

              {!!service?.description && (
                <Typography variant="subtitle2" fontStyle={"normal"}>
                  {service.description}
                </Typography>
              )}

              {!!service?.methodicalInstructionsContent && (
                <WrapperMethodicInstructions>
                  <StyledFlex $gap={1}>
                    <FlagIcon color={theme.palette.primary.main} />

                    <Typography
                      color={theme => theme.palette.primary.main}
                      fontWeight={700}
                      fontSize={18}
                      marginBottom={1}
                    >
                      {t(
                        Resources.Settings.Services.MethodicalInstructionsTitle,
                      )}
                    </Typography>
                  </StyledFlex>

                  {buttonVisibility[serviceIndex] ? (
                    <StyledFlex $gap={1}>
                      <Typography>
                        {t(
                          Resources.Settings.Services
                            .MethodicalInstructionsDescription,
                        )}
                      </Typography>
                      <BlButton
                        color="primary"
                        onClick={() => handleButtonClick(serviceIndex)}
                      >
                        {t(
                          Resources.Settings.Services
                            .MethodicalInstructionsButton,
                        )}
                      </BlButton>
                    </StyledFlex>
                  ) : (
                    <Box>
                      <Typography fontWeight={800} marginBottom={1}>
                        {t(
                          Resources.Settings.Services
                            .MethodicalInstructionsSubtitle,
                        )}
                      </Typography>
                      <HtmlContent
                        htmlString={service.methodicalInstructionsContent}
                      />
                    </Box>
                  )}
                </WrapperMethodicInstructions>
              )}

              {formService.isEnabled && (
                <Grid
                  container
                  gap={
                    formService.variants.filter(
                      x => getFrequency(x) === ServiceVariantFrequency.Single,
                    )?.length > 0
                      ? 5
                      : 0
                  }
                >
                  <Grid item>
                    {formService.variants
                      .filter(
                        x => getFrequency(x) === ServiceVariantFrequency.Single,
                      )
                      .map(formVariant => {
                        return (
                          <ServiceVariant
                            key={formVariant.serviceSettingServiceVariantID}
                            serviceSettingServiceVariantID={
                              formVariant.serviceSettingServiceVariantID
                            }
                            form={form}
                            formVariant={formVariant}
                            service={service}
                          />
                        );
                      })}
                  </Grid>
                  <Grid item>
                    {formService.variants
                      .filter(
                        x =>
                          getFrequency(x) === ServiceVariantFrequency.Monthly,
                      )
                      .map(formVariant => {
                        return (
                          <ServiceVariant
                            key={formVariant.serviceSettingServiceVariantID}
                            serviceSettingServiceVariantID={
                              formVariant.serviceSettingServiceVariantID
                            }
                            form={form}
                            formVariant={formVariant}
                            service={service}
                          />
                        );
                      })}
                  </Grid>
                </Grid>
              )}

              {!!fieldError?.message && (
                <StyledValidationText>
                  {fieldError?.message}
                </StyledValidationText>
              )}
            </div>
          </StyledService>
        );
      })}

      <Box marginTop={3.5} />
      <Divider />
      <Box marginBottom={3.5} />
    </>
  );
};
