import { Typography } from "@mui/material";
import {
  AddressType,
  EnterpriseEmailCommunicationMode,
  OrderDto,
  OrderInvoiceTaxWorkflowType,
  PartyDto,
} from "Api/Api";
import { StyledOrderSummaryText } from "Components/Orders/Summary/OrderSummary";
import { usePartyName } from "Hooks/Party/usePartyName";
import { Resources, useResource } from "Translations/Resources";
import {
  formatAddressMunicipality,
  formatAddressStreet,
} from "Utils/AddressUtils";
import { convertIbanToBankAccount } from "Utils/IbanUtils";
import { formatPhoneNumber } from "Utils/PhoneUtils";
import styled from "styled-components";

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${props => props.theme.spacing(0, 1)};
  margin-bottom: ${props => props.theme.spacing(3)};

  & > * {
    min-height: ${props => props.theme.spacing(2)};
  }
`;

type Props = {
  supplierParty: PartyDto;
  userCompanyParty: PartyDto | null | undefined;
  userParty: PartyDto;
  order: OrderDto;
  enterpriseEmailCommunicationMode?:
    | EnterpriseEmailCommunicationMode
    | null
    | undefined;
};

export const Provider: React.FunctionComponent<Props> = props => {
  const {
    supplierParty,
    order,
    enterpriseEmailCommunicationMode,
    userCompanyParty,
    userParty,
  } = props;
  const { t } = useResource();
  const { formatPartyName } = usePartyName();
  const supplierAddress = supplierParty?.addresses?.find(
    e => e.type === AddressType.Permanent,
  );

  const userCompanyAddress = userCompanyParty?.addresses?.find(
    e => e.type === AddressType.Permanent,
  );

  const isV2InvoiceWorkflowType =
    order.invoiceWorkflowType === OrderInvoiceTaxWorkflowType.V2;

  const showUserFormatName =
    !!enterpriseEmailCommunicationMode &&
    !isV2InvoiceWorkflowType &&
    !!userParty &&
    enterpriseEmailCommunicationMode ===
      EnterpriseEmailCommunicationMode.SupplierCompanyAndUser;

  return (
    <>
      <Typography variant="h2" marginBottom={1}>
        {t(Resources.Orders.Detail.Provider.Title)}
      </Typography>

      {order.invoiceWorkflowType === OrderInvoiceTaxWorkflowType.V2 && (
        <>
          {!!supplierParty && !!userCompanyParty && !!userCompanyAddress && (
            <>
              <StyledGrid>
                <StyledOrderSummaryText fontWeight={800}>
                  {formatPartyName(userCompanyParty)}
                </StyledOrderSummaryText>

                <div></div>

                <StyledOrderSummaryText>
                  {formatPartyName(supplierParty)}
                </StyledOrderSummaryText>

                <div></div>

                <StyledOrderSummaryText>
                  {t(Resources.Orders.Detail.Summary.CompanyNumber, {
                    companyNumber: userCompanyParty.companyNumber,
                  })}
                </StyledOrderSummaryText>

                <StyledOrderSummaryText>
                  {t(Resources.Orders.Detail.Summary.TaxNumber, {
                    taxNumber: userCompanyParty.taxNumber,
                  })}
                </StyledOrderSummaryText>
                <div>
                  <StyledOrderSummaryText>
                    {formatAddressStreet(userCompanyAddress, true)}
                  </StyledOrderSummaryText>

                  <StyledOrderSummaryText>
                    {formatAddressMunicipality(userCompanyAddress)}
                  </StyledOrderSummaryText>
                </div>
                <div>
                  <StyledOrderSummaryText>
                    {t(Resources.Orders.Detail.Summary.Phone, {
                      phone: formatPhoneNumber(userCompanyParty.phone),
                    })}
                  </StyledOrderSummaryText>

                  <StyledOrderSummaryText>
                    {t(Resources.Orders.Detail.Summary.Email, {
                      email: userCompanyParty.email,
                    })}
                  </StyledOrderSummaryText>
                </div>
              </StyledGrid>
            </>
          )}
        </>
      )}

      {/*Stare objenváky*/}
      {order.invoiceWorkflowType === OrderInvoiceTaxWorkflowType.V1 && (
        <>
          {!!supplierParty && (
            <>
              {showUserFormatName ? (
                <StyledOrderSummaryText fontWeight={800}>
                  {formatPartyName(userParty)}
                </StyledOrderSummaryText>
              ) : (
                <></>
              )}

              <StyledGrid>
                <StyledOrderSummaryText
                  fontWeight={showUserFormatName ? 400 : 800}
                >
                  {!!supplierParty.companyName
                    ? supplierParty.companyName
                    : `${supplierParty.firstName} ${supplierParty.lastName}`}
                </StyledOrderSummaryText>

                <StyledOrderSummaryText>
                  {t(Resources.Orders.Detail.Summary.CompanyNumber, {
                    companyNumber: supplierParty.companyNumber,
                  })}
                </StyledOrderSummaryText>
                {!!supplierAddress ? (
                  <div>
                    <StyledOrderSummaryText>
                      {formatAddressStreet(supplierAddress, true)}
                    </StyledOrderSummaryText>
                    <StyledOrderSummaryText>
                      {formatAddressMunicipality(supplierAddress)}
                    </StyledOrderSummaryText>
                  </div>
                ) : (
                  <div></div>
                )}

                <StyledOrderSummaryText>
                  {t(Resources.Orders.Detail.Summary.TaxNumber, {
                    taxNumber: supplierParty.taxNumber,
                  })}
                </StyledOrderSummaryText>

                <div></div>
                <div></div>

                <StyledOrderSummaryText>
                  {t(Resources.Orders.Detail.Summary.Phone, {
                    phone: formatPhoneNumber(supplierParty.phone),
                  })}
                </StyledOrderSummaryText>

                <StyledOrderSummaryText>
                  {t(Resources.Orders.Detail.Summary.Iban, {
                    iban: `${
                      convertIbanToBankAccount(supplierParty.iban)
                        ?.accountNumber ?? ""
                    }/${
                      convertIbanToBankAccount(supplierParty.iban)?.bankCode ??
                      ""
                    }`,
                  })}
                </StyledOrderSummaryText>

                <StyledOrderSummaryText>
                  {t(Resources.Orders.Detail.Summary.Email, {
                    email: supplierParty.email,
                  })}
                </StyledOrderSummaryText>

                <StyledOrderSummaryText>
                  {t(Resources.Orders.Detail.Summary.VariableSymbol, {
                    symbol: order?.number,
                  })}
                </StyledOrderSummaryText>
              </StyledGrid>
            </>
          )}
        </>
      )}
    </>
  );
};
