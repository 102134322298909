import { Typography } from "@mui/material";
import { BlDefaultButton } from "Components/Shared/Buttons/BlDefaultButton";
import {
  ScrollableContent,
  StyledDialog,
} from "Components/Shared/Dialogs/DialogStyles";
import HtmlContent from "Components/Shared/HtmlContent";
import { Resources, useResource } from "Translations/Resources";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { CrossIcon } from "Components/Shared/Icons";
import { Divider } from "@mui/material";

type Props = {
  methodicalInstructionsContent: string;
  serviceName: string;
  onClosed: () => void;
};

export const MethodicalInstructionsDialog: React.FunctionComponent<
  Props
> = props => {
  const { methodicalInstructionsContent, onClosed, serviceName } = props;
  const { t } = useResource();

  return (
    <>
      <StyledDialog open={true} onClose={onClosed}>
        <ScrollableContent>
          <StyledFlex $alignItems="center" $justifyContent="space-between">
            <Typography variant="h2">
              {t(Resources.Settings.Services.MethodicalInstructionsTitle)}
            </Typography>

            <BlDefaultButton
              title={t(Resources.Common.Close)}
              onClick={() => onClosed()}
            >
              <CrossIcon />
            </BlDefaultButton>
          </StyledFlex>

          <Divider sx={{ marginTop: 1, marginBottom: 2 }} />

          <Typography fontWeight={800}>{serviceName}</Typography>

          <Typography>
            {
              <HtmlContent
                htmlString={methodicalInstructionsContent}
              ></HtmlContent>
            }
          </Typography>
        </ScrollableContent>
      </StyledDialog>
    </>
  );
};
