import {
  EmailVerificationCommandResult,
  getInvoicesUserIssuedInvoicesISDOCPdfs,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { downloadBase64File } from "Utils/FileUtils";
import { Resources, getTranslation } from "Translations/Resources";
import { formatDate } from "Utils/DateUtils";

export const getAllUserIssuedInvoicePdfsAsync = createAsyncAction(
  "@invoice/GET_ALL_ISSUED_REQUEST",
  "@invoice/GET_ALL_ISSUED_RESPONSE",
  "@invoice/GET_ALL_ISSUED_FAILURE",
)<void, void, Error>();

function* getAllUserIssuedInvoicePdfs(
  action: ReturnType<typeof getAllUserIssuedInvoicePdfsAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getInvoicesUserIssuedInvoicesISDOCPdfs,
    );

    if (status === 200) {
      downloadBase64File(
        data.isdocPdfsZipBase64,
        "application/zip",
        `${getTranslation(
          Resources.Settings.Invoices.InvoicesList.DownloadAll.File,
        )}_${formatDate(new Date(), true, true)}.zip`,
      );
      yield put(getAllUserIssuedInvoicePdfsAsync.success());
      return;
    }

    yield put(
      getAllUserIssuedInvoicePdfsAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(getAllUserIssuedInvoicePdfsAsync.failure(err as Error));
  }
}
export function* getAllUserIssuedInvoicePdfsSaga() {
  yield takeLatest(
    getType(getAllUserIssuedInvoicePdfsAsync.request),
    getAllUserIssuedInvoicePdfs,
  );
}

export const getAllUserIssuedInvoicePdfsSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EmailVerificationCommandResult>(false),
  name: "@invoice/getAllUserIssuedInvoicePdfs",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EmailVerificationCommandResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getAllUserIssuedInvoicePdfsAsync, builder);
    handleSuccessCase(getAllUserIssuedInvoicePdfsAsync, builder);
    handleFailureCase(getAllUserIssuedInvoicePdfsAsync, builder);
  },
});

export const { resetSlice } = getAllUserIssuedInvoicePdfsSlice.actions;
