import {
  EmailVerificationCommandResult,
  getApiUrl,
  getOrdersTermAndConditionsFilePath,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { downloadFile } from "Utils/FileUtils";
import FileSaver from "file-saver";

export const downloadTermAndConditionFileAsync = createAsyncAction(
  "@user/DOWNLOAD_TERM_AND_CONDITION_FILEREQUEST",
  "@user/DOWNLOAD_TERM_AND_CONDITION_FILERESPONSE",
  "@user/DOWNLOAD_TERM_AND_CONDITION_FILEFAILURE",
)<void, void, Error>();

function* downloadTermAndConditionFile(
  action: ReturnType<typeof downloadTermAndConditionFileAsync.request>,
): Generator {
  try {
    const response = yield* downloadFile(
      getApiUrl() + getOrdersTermAndConditionsFilePath(),
      "GET",
    );
    if (response.clone.status === 200) {
      response.clone.blob().then(blob => {
        FileSaver.saveAs(blob, "OP");
      });
      yield put(downloadTermAndConditionFileAsync.success());
    }

    yield put(
      downloadTermAndConditionFileAsync.failure(
        new Error(response.clone.statusText),
      ),
    );
  } catch (err) {
    yield put(downloadTermAndConditionFileAsync.failure(err as Error));
  }
}
export function* downloadTermAndConditionFileSaga() {
  yield takeLatest(
    getType(downloadTermAndConditionFileAsync.request),
    downloadTermAndConditionFile,
  );
}

export const downloadTermAndConditionFileSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EmailVerificationCommandResult>(false),
  name: "@user/downloadTermAndConditionFile",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EmailVerificationCommandResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(downloadTermAndConditionFileAsync, builder);
    handleSuccessCase(downloadTermAndConditionFileAsync, builder);
    handleFailureCase(downloadTermAndConditionFileAsync, builder);
  },
});

export const { resetSlice } = downloadTermAndConditionFileSlice.actions;
