import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { OrderDto } from "Api/Api";
import { OrderSummary } from "Components/Orders/Summary/OrderSummary";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { BlCheckboxInput } from "Components/Shared/Inputs/Form/BlCheckboxInput";
import { PageTitle } from "Components/Shared/PageTitle";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { getOrderServicesAsync } from "State/Orders/Detail/OrderServicesSlice";
import { completeDraftAsync } from "State/OrderWorkflow/Slices/CompleteDraftSlice";
import { Step, setStep } from "State/Orders/Draft/DraftSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { ObjectSchema, boolean, object } from "yup";
import { toast } from "sonner";
import { useParty } from "Hooks/Party/useParty";

type Props = {
  order: OrderDto;
};

type FormModel = {
  areTermsAccepted: boolean;
};

export const DraftSummary: React.FunctionComponent<Props> = props => {
  const { order } = props;
  const { t } = useResource();

  const validationSchema: ObjectSchema<FormModel> = object().shape({
    areTermsAccepted: boolean()
      .required()
      .test(
        "areTermsAccepted",
        t(Resources.Orders.Detail.Summary.AreTermsAccepted.Validation),
        value => value,
      ),
  });

  const form = useForm<FormModel>({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
    defaultValues: {
      areTermsAccepted: false,
    },
  });

  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors },
  } = form;

  const dispatch = useAppDispatch();

  const { party: supplierParty } = useParty(order.supplierPartyPublicID);
  const { party: clientParty } = useParty(order.clientPartyPublicID);
  const { party: userParty } = useParty(order.userPartyPublicID);
  const { party: userCompanyParty } = useParty(order.userCompanyPartyPublicID);
  const emailCommunicationMode = useAppSelector(
    x => x.enterprise.basicSettings.settings.data?.emailCommunicationMode,
  );

  useEffect(() => {
    dispatch(getOrderServicesAsync.request({ publicID: order.publicID }));
  }, [dispatch, order.publicID]);

  const { data: orderServices } = useAppSelector(e => e.order.orderServices);

  const navigate = useNavigate();

  const process = async () => {
    await trigger();

    handleSubmit(() => {
      dispatch(
        completeDraftAsync.request({ publicID: order.publicID, navigate }),
      );
    })();
  };

  const isLoading =
    !clientParty ||
    !supplierParty ||
    !orderServices?.items ||
    !userParty ||
    !emailCommunicationMode;

  return (
    <>
      <PageTitle
        title={t(Resources.Orders.Detail.Summary.Title)}
        onChevronClick={() => window.location.reload()}
      ></PageTitle>

      <OrderSummary
        order={order}
        clientParty={clientParty}
        supplierParty={supplierParty}
        orderServices={orderServices?.items}
        isLoading={isLoading}
        userParty={userParty}
        userCompanyParty={userCompanyParty}
        enterpriseEmailCommunicationMode={emailCommunicationMode}
        token={null}
      />

      <Box marginBottom={3} />

      <form>
        <BlCheckboxInput
          control={control}
          errors={errors}
          name="areTermsAccepted"
          label={t(Resources.Orders.Detail.Summary.AreTermsAccepted.Label)}
          hasErrorMessage
        />
      </form>

      <StyledFlex $gap={3} $marginTop={1}>
        <BlButton
          color="primary"
          onClick={() => process()}
          disabled={isLoading}
        >
          {t(Resources.Orders.Detail.Summary.Process)}
        </BlButton>

        <BlButton
          color="secondary"
          onClick={() => {
            toast.success(t(Resources.Orders.Detail.SavedSuccess));
            navigate(getPath(AppRouting.Dashboard));
          }}
          disabled={isLoading}
        >
          {t(Resources.Common.SaveAndClose)}
        </BlButton>

        <BlButton
          color="secondary"
          onClick={() => dispatch(setStep(Step.Form))}
          disabled={isLoading}
        >
          {t(Resources.Common.PreviousStep)}
        </BlButton>
      </StyledFlex>
    </>
  );
};
