import { Typography } from "@mui/material";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { downloadTermAndConditionFileAsync } from "State/Users/DownloadTermAndConditionFile";
import { useResource, Resources } from "Translations/Resources";
import React from "react";
import styled from "styled-components";

const StyledText = styled.div<{ maxWidth?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.spacing(1, 0)};
  opacity: 0.32;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : "unset")};

  ${props => props.theme.breakpoints.down("md")} {
    max-width: unset;
  }
`;

export const Copyright: React.FunctionComponent<{
  maxWidth?: number;
  showTermAndConditions: boolean;
}> = props => {
  const { showTermAndConditions } = props;

  const { t } = useResource();
  const dispatch = useAppDispatch();
  const downloadFile = () => {
    dispatch(downloadTermAndConditionFileAsync.request());
  };

  return (
    <StyledText
      maxWidth={props.maxWidth}
      title={import.meta.env.VITE_VERSION?.replace("PaidAdvisory-", "")}
    >
      {showTermAndConditions ? (
        <>
          <StyledFlex>
            <Typography component="span">
              Užíváním platformy Placeného poradenství souhlasíte s{" "}
            </Typography>
            <Typography component="span">&nbsp;</Typography> {/* Mezera */}
            <Typography
              component="span"
              onClick={downloadFile}
              fontWeight={800}
              sx={{ cursor: "pointer" }}
            >
              Obchodními podmínkami
            </Typography>
          </StyledFlex>
        </>
      ) : (
        <Typography>© {t(Resources.Common.AppName)}</Typography>
      )}
    </StyledText>
  );
};
