import { Copyright } from "Components/Layout/CopyRight";
import * as React from "react";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";
import { Menu } from "./Menu";

const StyledLayoutWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: ${props => props.theme.vh(100)};
`;

const StyledContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
`;

const StyledCopyright = styled.div`
  padding-top: ${props => props.theme.spacing(2)};
`;

type Props = {
  title: string;
};

export const AuthenticatedLayout: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { t } = useResource();
  const { title } = props;

  React.useEffect(() => {
    document.title = `${title} | ${t(Resources.Common.AppName)}`;
  }, [title, t]);

  return (
    <StyledLayoutWrapper>
      <Menu></Menu>

      <StyledContent>{props.children}</StyledContent>

      <StyledCopyright>
        <Copyright showTermAndConditions={true} />
      </StyledCopyright>
    </StyledLayoutWrapper>
  );
};
