import { Typography } from "@mui/material";
import { PartyDto } from "Api/Api";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { StyledContentWrapper } from "Components/Shared/StyledComponents";
import { formatCompanyWithAddress } from "Utils/PartyUtils";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { styled } from "styled-components";

const T = styled(Typography)``;
type Props = {
  userCompanyParty: PartyDto | undefined;
  token: string;
  isAdvisorDetail: boolean;
};

export const TermsAndConditionsV2: React.FunctionComponent<Props> = props => {
  const { userCompanyParty, token, isAdvisorDetail } = props;

  return (
    <StyledContentWrapper $fullPadding>
      <T>
        <b>I. Úvod </b>
      </T>
      <T>
        1. Tyto Všeobecné obchodní podmínky poskytování placeného poradenství
        (dále jen „VOP“) spolu s objednávkou služeb a Podmínkami zpracování
        osobních údajů představují Smlouvu mezi poskytovatelem placeného
        poradenství {formatCompanyWithAddress(userCompanyParty)}
        (dále jen „poradce“), a klientem.
      </T>

      <T>
        2. Se zněním VOP se klient seznámil před potvrzením závazné objednávky
        placeného poradenství a vyjádřil s nimi svůj souhlas.
      </T>
      <T>
        3. VOP upravují podmínky poskytování placeného poradenství poradcem
        klientovi. Placené poradenství je poradcem poskytováno prostřednictvím
        platformy, kterou provozuje SAB servis, s.r.o., IČO: 24704008, se sídlem
        Praha 1 - Nové Město, Jungmannova 748/30, PSČ 11000 (dále jen „
        <i>SAB</i>“).
      </T>
      <T marginTop={2}>
        <b>II. Potvrzení objednávky a předmět poskytovaných služeb </b>
      </T>
      <T>
        1. Klient kontaktuje poradce s požadavkem na poskytnutí služeb placeného
        poradenství, přičemž nejprve dojde k dohodě mezi klientem a poradcem na
        rozsahu a podmínkách těchto služeb. Následně poradce zašle klientovi
        návrh objednávky, v němž je uveden zejména rozsah služeb placeného
        poradenství, jeho cena a platební podmínky. Proces probíhá v platformě
        poskytované SAB.
      </T>
      <T>
        2. V návrhu objednávky nejprve vyplní Poradce od klienta sdělené údaje
        potřebné pro uzavření Smlouvy (mimo jiné telefonní číslo a email
        potřebné pro následující kroky). Poté v návrhu objednávky vybere poradce
        pro klienta ty z nabízených služeb poradce, o které klient projevil
        zájem. Tyto služby jsou blíže popsány v čl. IV. Služby jsou poskytovány
        individuálně anebo v rámci balíčku. Služby mohou být poskytovány formou
        předplatného na pravidelné bázi anebo jako jednorázové plnění.
      </T>
      <T>
        3. Návrh objednávky je poté zaslán klientovi emailem. Odesláním návrhu
        je tento návrh závazný. Klient má poté lhůtu xx dní k potvrzení návrhu
        objednávky. Klient má v této lhůtě čas na rozmyšlení, zda chce návrh
        objednávky akceptovat.
      </T>
      <T>
        4. Znění návrhu objednávky je klientovi dostupné v prokliku odkazu
        uvedeného v emailu dle předchozího odstavce 3. Po otevření odkazu má
        klient k dispozici objednávku ke kontrole. Dále má k dispozici klient
        VOP a Podmínky zpracování osobních údajů.
      </T>
      <T>
        5. Souhlasí-li údaje uvedené v objednávce a klient potvrdí její znění,
        udělí tím i souhlas se zněním VOP a Podmínkami zpracování osobních
        údajů. Klient může také udělit souhlas se zpracováním osobních údajů v
        SAB pro účely přístupu do interního systému SAB (SAB se stane správcem
        osobních údajů v tomto rozsahu). Okamžikem potvrzení objednávky klientem
        je objednávka pro klienta závazná a klient se zavazuje hradit služby
        sjednaným způsobem (předem nebo na základě vystavené faktury).
      </T>
      <T>
        6. Klient potvrzením objednávky bere na vědomí a výslovně souhlasí s
        tím, že k poskytnutí či poskytování služby může dojít před uplynutím
        lhůty pro odstoupení od Smlouvy, čímž zaniká právo klienta na odstoupení
        od Smlouvy do 14 dní od uzavření Smlouvy. Tento stav vychází z druhu
        plnění, které představuje činnosti upravené dle § 1837 odst. 1 písm. a)
        a l) zákona č. 89/2012 Sb., občanský zákoník, nejedná se tedy o zkrácení
        práva spotřebitele.
      </T>
      <T>
        7. Potvrzení objednávky klientem probíhá elektronicky v závislosti na
        zvolené službě:
        <ul>
          <li>
            a) elektronickým uzavřením prostřednictvím SMS odeslané na číslo
            sdělené klientem poradci, pokud byl zvolen způsob úhrady až po
            poskytnutí služby či služeb na základě vystavené faktury;
          </li>
          <li>
            b) úhradou smluvené odměny na bankovní účet poradce, pokud byl
            zvolen způsob úhrady před poskytnutím služby či služeb.
          </li>
        </ul>
      </T>
      <T>
        8. Okamžikem přijetí potvrzení objednávky klienta je uzavřena Smlouva
        mezi poradcem a klientem. Potvrzení objednávky a tedy uzavřené Smlouvy
        je klientovi doručeno na jím zadanou e-mailovou adresu/telefonní číslo.
      </T>
      <T>
        9. Poradce se zavazuje poskytovat konkrétně ty služby, které jsou
        uvedeny v potvrzené objednávce dle rozsahu a podmínek upravených v čl.
        IV. anebo dle individuálního požadavku klienta, který může poradce
        akceptovat.
      </T>
      <T>
        10. Na základě první potvrzené objednávky je také uzavřen rámcový vztah
        o poskytování placeného poradenství. Tento rámcový vztah trvá po dobu
        poskytování služeb a další objednávky představují jeho dílčí obsah
        (dílčí smlouvy). V rámci případných dalších jednotlivých objednávek se
        proto poskytují služby objednané klientem na základě separátních dílčích
        smluv.
      </T>
      <T>
        11. Při poskytování služeb podle Smlouvy, zejména při zpracování
        písemností a při jednání s třetími osobami jménem klienta, se poradce
        řídí zadáním a pokyny klienta. Těmito pokyny však není poradce vázán,
        jsou-li v rozporu s právními předpisy, na což je povinen klienta
        upozornit. Poradce je povinen upozornit klienta na zřejmě nevhodné
        pokyny, příp. pokyny, jejichž důsledky provedení jsou zpravidla spojeny
        se značným rizikem pro klienta.
      </T>
      <T>
        12. Služby jsou poskytovány prostřednictvím následujících osob: členů
        statutárního orgánu poradce, je-li poradce právnickou osobou, jeho
        zaměstnanců a externích spolupracovníků. Výše uvedené osoby v rámci
        plnění dle Smlouvy jednání jménem poradce. Klient je oprávněn udílet
        pokyny pouze těm osobám, které se podílejí na poskytování konkrétní
        služby dle Smlouvy (tato osoba bude poradcem klientovi sdělena).
      </T>
      <T>
        13. Klient se zavazuje poskytnout poradci veškerou potřebnou součinnost
        k provádění činností podle Smlouvy, zejména se mu zavazuje poskytnout
        veškeré potřebné úplné a pravdivé informace a podklady. V případě
        poskytnutí neúplných nebo nepravdivých informací nebo podkladů klientem
        neodpovídá poradce za škodu, která tím klientovi vznikne. V případě
        prodlení s poskytnutím údajů potřebných pro výkon Smlouvy ze strany
        klienta poradce neodpovídá za nedodržení dohodnutého časového
        harmonogramu.
      </T>
      <T>
        14.{" "}
        <b>
          Předmětem Smlouvy není poskytování žádných investičních služeb podle
          zákona o podnikání na kapitálovém trhu, tedy ani investiční služby
          investiční poradenství týkající se investičních nástrojů, ani
          investiční služby přijímání a předávání pokynů týkajících se
          investičních nástrojů včetně zprostředkování obchodů s investičními
          nástroji. Předmětem Smlouvy není ani zprostředkování pojištění, včetně
          provádění dalších přípravných prací směřujících ke sjednání, změně
          nebo ukončení pojištění, včetně poskytování doporučení vedoucího ke
          sjednání, změně nebo ukončení pojištění, ani zprostředkování
          spotřebitelského úvěru, včetně provádění přípravných prací směřujících
          ke sjednání spotřebitelského úvěru anebo poskytování doporučení
          vedoucího ke sjednání spotřebitelského úvěru. Předmětem této smlouvy
          taktéž nejsou činnosti realitního zprostředkování. Tyto činnosti
          poradce podle Smlouvy neposkytuje a poskytovat nesmí.{" "}
        </b>
      </T>
      <T>
        15. V případech, kdy poradce vykonává pro klienta i zprostředkovatelské
        činnosti v oblastech uvedených v odstavci 14 výše, klient bere na vědomí
        a souhlasí s tím, že předmětem poskytování služeb placeného poradenství
        tato zprostředkovatelská činnost poradce není.
      </T>
      <T>
        16. Poradce při poskytování služeb podle Smlouvy neodpovídá za
        rozhodnutí klienta a jeho důsledky.
      </T>
      <T marginTop={2}>
        <b>III. Odměna </b>
      </T>
      <T>
        1. Za služby podle Smlouvy bude klientovi účtována smluvní odměna podle
        dohody smluvních stran, a to buď jako paušální za sjednané období
        (zejména měsíc nebo rok), anebo jednorázová za poskytnutou službu.
        Konkrétní výše odměny a fakturační období je určeno v potvrzené
        objednávce klienta.
      </T>
      <T>
        2. Veškeré ceny za služby dle této Smlouvy podléhají DPH. Pokud by
        poradce nebyl plátce DPH, není odměna ze strany poradce o DPH navýšena,
        a to až do doby, než se stane poradce plátcem DPH. Od tohoto okamžiku
        budou platby navýšeny o příslušnou sazbu DPH. Poradce o této skutečnosti
        informuje klienta s předstihem, a to nejméně před odesláním další
        faktury. Poradce je případně oprávněn v situaci, kdy se stává plátcem
        DPH, jednostranně snížit odměnu za poskytované služby tak, aby snížení
        výše odměny kompenzovalo navýšení ceny o DPH za poskytované služby (tak,
        aby klient platil stále stejně).
      </T>
      <T>
        3. Dohodnutá odměna zahrnuje i náklady na poštovné a náhradu za
        telefonní a faxové poplatky na území ČR. Odměna však nezahrnuje případné
        správní náklady a další poplatky za služby, které poradce pro klienta
        vykonává.
      </T>
      <T>
        4. Odměna je splatná jedním z následujících způsobů:
        <ul>
          <li>
            a) Po poskytnutí služby na základě faktury poradce, která bude
            zahrnovat služby uvedené ve Smlouvě ve výši smluvené v objednávce,
            není-li dohodnuto jinak.
          </li>
          <li>
            b) Předem, když objednávka bude obsahovat platební údaje a
            prohlášení, že zaplacením klient projevuje závazně souhlas s
            objednávkou – daňový doklad je klientovi vystaven vždy po připsání
            platby na účet uvedený v objednávce.
          </li>
        </ul>
      </T>
      <T>
        5. V případě prodlení klienta s úhradou ceny je klient povinen zaplatit
        poradci smluvní pokutu za prodlení se splněním platební povinnosti ve
        výši 0,1 % z dlužné částky za každý den prodlení klienta s úhradou
        dlužné částky. Klient se zavazuje zaplatit takto vzniklou smluvní pokutu
        sedmý pracovní den následující po dni, kdy poradce tuto smluvní pokutu
        uplatní.
      </T>
      <T marginTop={2}>
        <b>IV. Popis jednotlivých služeb </b>
      </T>
      <T>
        1. V rámci platformy SAB jsou k dispozici tyto služby:
        <ul>
          <li>
            <i>Správa smluv v MyPlann,</i>
          </li>
          <li>
            <i>Ekonomické novinky emailem,</i>
          </li>
          <li>
            <i>Asistence s termíny a povinnostmi,</i>
          </li>
          <li>
            <i>Asistence s reklamacemi,</i>
          </li>
          <li>
            <i>Krizový plán pro jednotlivce nebo rodinu,</i>
          </li>
          <li>
            <i>Vyřizování agendy za klienta,</i>
          </li>
          <li>
            <i>Asistence s řešením pojistných událostí,</i>
          </li>
          <li>
            <i>Služby optimalizace výdajů domácnosti,</i>
          </li>
          <li>
            <i>Poradce na telefonu,</i>
          </li>
          <li>
            <i>Přednostní odbavení e-mailových požadavků,</i>
          </li>
          <li>
            <i>Garance EUCS,</i>
          </li>
          <li>
            <i>Pomoc s rozvodem,</i>
          </li>
          <li>
            <i>Asistence s daňovým přiznáním,</i>
          </li>
          <li>
            <i>Komplexní audit finančního zabezpečení,</i>
          </li>
          <li>
            <i>Reality,</i>
          </li>
          <li>
            <i>Energetické poradenství,</i>
          </li>
          <li>
            <i>Prověření smluv na jiné než finanční produkty,</i>
          </li>
          <li>
            <i>Průběžné finanční plánování.</i>
          </li>
        </ul>
        Tyto služby lze poskytovat individuálně anebo formou balíčku. Balíčky
        sestavuje poradce se zaměřením na potřeby svých klientů. Je-li služba
        pouze v rámci balíčku, nemusí ji poradce poskytovat samostatně. V
        závislosti na zvoleném druhu služby a domluvě mezi poradcem a klientem
        se může jednat o jednorázovou nebo opakující se činnost.
      </T>

      <T>
        2. Popis služeb včetně jejich konkrétního vymezení a informací o jejich
        obsahu pro spotřebitele je vymezen níže v následujících bodech:
      </T>
      <T marginTop={2}>
        <b>2.1. Správa smluv v myPLANN </b>
      </T>
      <T>
        Předmětem této služby je administrativní činnost poradce, který si od
        klienta nechá předložit veškeré stávající smlouvy klienta, a to dle jeho
        volby i na vybrané nefinanční produkty. Poradce tak v součinnosti s
        klientem a producenty neustále udržuje vybranou smluvní dokumentaci
        kompletní a aktuální bez ohledu na to, zda danou smlouvu poradce
        zprostředkoval či nikoli. Klient má díky tomu v myPLANNu na jednom místě
        přehledně vše, co poradci poskytne klient či přímo producent, vč. výpisů
        a dalších důležitých informací, o nichž pak poradce průběžně informuje
        klienta dle domluvy. Klient má tedy smlouvy pohromadě a poradce v
        případě zjištění nedostatků po domluvě s klientem aktivně řeší
        nedostatky s producentem.
      </T>
      <T>
        <b>Podstata a rozsah služby "Správa smluv v MyPLANN": </b>
      </T>
      <T>
        Tato služba byla navržena pro klienty, kteří požadují komplexní a
        proaktivní správu všech svých smluvních dokumentů týkajících se
        finančních i nefinančních produktů dle volby klienta. Služba zahrnuje:
        <ul>
          <li>
            Komplexní sběr a archivaci stávajících a nově vznikajících smluv
            klienta, a to i těch, které nebyly zprostředkovány poradcem.
          </li>
          <li>
            Průběžné nahrávání aktualizací všech dokumentů, aby reflektovaly
            veškeré změny ve smluvních vztazích.
          </li>
          <li>
            Aktivní komunikaci s producenty po domluvě s klientem pro vyjasnění
            a opravu jakýchkoliv nedostatků či chyb v dokumentech.
          </li>
        </ul>
      </T>
      <T>
        <b>Nadstandardní funkce služby a důležitá upozornění: </b>
        Na rozdíl od zákonné povinnosti poskytovatelů služeb, která se typicky
        omezuje na základní správu smluv, které poradce zprostředkoval a které
        se týkají výhradně finančních produktů, tato služba se týká všech smluv
        dle výběru klienta a zajišťuje, že klient má veškeré dokumenty přehledně
        k dispozici včetně jejich aktualizací.
      </T>
      <T marginTop={2}>
        <b>2.2. Ekonomické novinky emailem </b>
      </T>
      <T>
        Průběžný monitoring informací z finančního trhu s tím, že veškeré
        novinky jsou vytvářeny jako administrativní činnost (filtrování novinek
        na míru klientovi a jeho preferencí). Klient dostává newsletter z jím
        předvybraných oblastí investic, pojištění, bankovnictví, realit a
        aktuálního dění na trhu. Klient proto nemusí neustále řešit, co se ho
        týká a co ne a poradce mu tyto informace tvoří na míru.
      </T>
      <T>
        <b>Podstata a rozsah služby "Ekonomické novinky emailem": </b>
        Služba spočívá v průběžném monitoringu informací z finančního trhu a
        zasílání novinek klientovi emailem na základě jeho preferencí. Novinky
        jsou vytvářeny jako administrativní činnost filtrováním informací na
        míru klientovi z oblastí investic, pojištění, bankovnictví, realit a
        aktuálního dění na trhu. Klient tak dostává přehled relevantních
        informací, aniž by je musel sám vyhledávat.
      </T>
      <T>
        <b>Nadstandardní funkce služby a důležitá upozornění: </b>
        Novinky jsou klientovi zasílány personalizovaně, z oblastí zájmu na
        základě jeho preferencí. Mají pouze obecný a informativní charakter a
        nejedná se o personalizované investiční doporučení ani nabídku
        konkrétních produktů či služeb. Novinky nejsou ani reklamním sdělením a
        jejich účelem není propagace či podpora prodeje jakýchkoliv produktů.
        Klient má právo svůj souhlas kdykoli odvolat a službu bezplatně ukončit.
        Ukončení služby nemá vliv na podmínky jiných produktů, které klient
        využívá. Služba je dobrovolná a nejedná se o diskriminaci klientů, kteří
        ji nevyužijí.
      </T>
      <T marginTop={2}>
        <b>2.3. Asistence s termíny a povinnostmi </b>
      </T>
      <T>
        V rámci této služby poradce hlídá a kontroluje důležité termíny, které
        se týkají např. výročí smluv, fixací, konce investičních horizontů,
        úhrad pojistného apod., a to u všech smluv vč. těch, které poradce
        nesjednával a těch, které se netýkají finančních produktů. Všechna tato
        důležitá data poradce sleduje v souvislosti s aktuální finanční situací
        klienta – v rámci služby má klient k dispozici také přehled cashflow
        tak, aby měl nejen kdykoli k dispozici detailní přehled jeho příjmů a
        výdajů, ale také aby měl jistotu, že finančně zvládne své závazky s
        ohledem na blížící se termíny. Poradce v době blížícího se termínu
        kontaktuje klienta, kterého o tom informuje a navrhne případný další
        postup. V rámci této služby může poradce se souhlasem klienta aktivně
        komunikovat přímo s producenty.
      </T>
      <T>
        <b>Podstata a rozsah služby "Asistence s termíny a povinnostmi": </b>V
        rámci této služby poradce hlídá a kontroluje důležité termíny, které se
        týkají např. výročí smluv, fixací, konce investičních horizontů, úhrad
        pojistného apod., a to u všech smluv vč. těch, které poradce nesjednával
        a těch, které se netýkají finančních produktů. Všechna tato důležitá
        data poradce sleduje v souvislosti s aktuální finanční situací klienta –
        v rámci služby má klient k dispozici také přehled cashflow tak, aby měl
        nejen kdykoli k dispozici detailní přehled jeho příjmů a výdajů, ale
        také aby měl jistotu, že finančně zvládne splátky či další finanční
        výdaje. V rámci této služby poradce také v případě potřeby aktivně
        komunikuje s producenty/poskytovateli služeb, tedy oproti standardu to
        není klient, který se na poradce obrací s požadavkem, ale je to sám
        poradce, který detekuje potřebu danou věc bez zbytečného odkladu řešit.
        Tato služba se zaměřuje na:
        <ul>
          <li>
            Hlídání důležitých termínů: Poradce monitoruje termíny jako výročí
            smluv, fixace, konce investičních horizontů a úhrady pojistného, a
            to nejen u smluv sjednaných poradcem, ale i u těch, které nebyly
            sjednány jeho prostřednictvím a které se tak ani nemusí týkat
            finančních služeb.
          </li>
          <li>
            Přehled cashflow a jeho zohlednění ve vztahu k důležitým termínům a
            povinnostem klienta: Klient má k dispozici detailní přehled svých
            příjmů a výdajů, což mu umožňuje lépe plánovat své finanční závazky
            a zajišťuje, že bude schopen splácet své povinnosti včas.
          </li>
          <li>
            Aktivní komunikace: Poradce proaktivně informuje klienta o blížících
            se termínech a kontroluje, zda byly splněny jeho povinnosti (např.
            úhrada pojistného).
          </li>
        </ul>
      </T>
      <T>
        <b>Nadstandardní funkce služby a důležitá upozornění: </b>
        Podstata a rozsah služby zaručuje, že je klientovi poskytována
        nadstandardní péče, než na jakou má právo při běžné zprostředkovatelské
        činnosti poradce. Službu proto není vhodné čerpat, má-li klient pouze
        jednorázové požadavky týkající se výhradně smluv ve správě poradce jako
        je např. změna výše pojistného nebo posunu splatnosti. Klient je také
        povinen předem zvážit vhodnost využití této služby zejména s ohledem na
        předejití částečné duplicity s čerpáním jiných obdobných služeb, zejména{" "}
        <i>Správa smluv v myPLANN</i>, která též zahrnuje vedení a správu
        veškeré smluvní dokumentace dle požadavku klienta vč. upozornění na
        potřebu jakéhokoli zásahu, ovšem jejím obsahem není individualizované
        průběžné sledování cashflow klienta (rozdílu mezi jeho příjmy a výdaji)
        vůči jeho budoucím závazkům. Nebudou-li poskytnuty poradci veškeré
        informace od klienta, neodpovídá poradce za promeškání termínů, které mu
        nebyly známy.
      </T>

      <T marginTop={2}>
        <b>2.4. Asistence s reklamacemi </b>
      </T>
      <T>
        Služba představuje doplňkovou službu k základní podpoře při řešení
        reklamací a stížností, kterou má každý klient právo očekávat při
        poskytování standardních finančních služeb. Poradce poskytuje rozšířenou
        podporu a konzultace pro rychlé a efektivní řešení jakýchkoliv problémů
        nebo reklamací. U finančních produktů a služeb tak poradce ve prospěch
        klienta zúročí své zkušenosti a nadstandardní vztahy s producenty, které
        mohou přispět k urychlení vyřešení vzniklé situace a u nefinančních
        produktů přinejmenším všeobecné zkušenosti s řešením krizových situací.
        Služba tak přináší personalizovanější podporu klienta v nejrůznějších
        situacích.
      </T>
      <T>
        <b>Podstata a rozsah služby "Asistence s reklamacemi": </b>
        Tato služba poskytuje rozšířenou podporu a konzultace pro rychlé a
        efektivní řešení jakýchkoliv problémů, stížností a/nebo reklamací
        spojených s produkty a službami, s nimiž jste nebyli spokojeni. Pokud se
        jedná o finanční služby, které Vám poradce zprostředkoval, k vyřešení
        situace mohou přispět znalosti a nadstandardní vztahy poradce s
        producenty. Také u všech ostatních služeb, u nichž jste se setkali s
        problémem, však k vyřešení věci může přispět zkušenost poradce s řešením
        různých kritických situací.
      </T>
      <T>
        <b>Nadstandardní funkce služby a důležitá upozornění: </b>
        Bezplatná pomoc s řešením reklamací a stížností, které se týkají
        finančních služeb, které pro Vás poradce zprostředkoval, je standardní
        povinností každého poskytovatele služeb. Doplňková služba "Asistence s
        reklamacemi" je nabízena nad rámec zákonných požadavků a je určena pro
        ty, kteří preferují získat rychlejší a personalizovanější podporu i s
        produkty a službami, které nejsou ve správě poradce. V některých
        případech může být požadována Vaše součinnost, např. poskytnutí plné
        moci. Nejedná se o poskytování právních či advokátních služeb.
      </T>
      <T marginTop={2}>
        <b>2.5. Krizový plán pro jednotlivce nebo rodinu </b>
      </T>
      <T>
        Tato služba zahrnuje komplexní evidenci, analýzu a management veškerého
        majetku, vč. finančního portfolia (pohledávek, likvidního i nelikvidního
        majetku, předpokládaných příjmů a výdajů v dlouhodobém horizontu), s
        cílem efektivně minimalizovat rizika potenciální krizové situace dle
        konkrétního požadavku klienta. Poskytování služby vychází z nadčasových
        postupů a je zacíleno na zlepšení připravenosti na nepředvídatelné
        události jednotlivce nebo celé rodiny dle volby klienta, s důrazem na
        likviditu aktiv a zajištění nepřetržitého pokrytí provozních nákladů
        domácnosti i v případě vzniku krizové situace. Výsledkem je komplexní
        krizový plán dle potřeb klienta.
      </T>
      <T>
        <b>
          Podstata a rozsah služby "Krizový plán pro jednotlivce nebo rodinu":{" "}
        </b>
        Služba je nadstandardní službou, která překračuje zákonné povinnosti
        tradičního finančního poradenství a specializuje se na krizové plánování
        za účelem eliminace důsledků rizik specifikovaných klientem, a to za
        účelem zajištění likvidity aktiv a nepřetržitého pokrytí provozních
        nákladů domácnosti jednotlivce či celé domácnosti (dle preferencí
        klienta) i v případě vzniku krizové situace. Tato služba zahrnuje
        komplexní evidenci movitého a nemovitého majetku, (dlouhodobých)
        pohledávek, závazků a finančního portfolia a následně vytvoření a
        aktualizace efektivní strategie pro řešení konkrétních krizových
        událostí. Krizové situace si definují klient s poradcem v závislosti na
        potřebě klienta, může se jednat například o rizika v důsledku rozvodu
        (ztráta bydlení), smrti (dědické řízení s dluhy) nebo další rizika
        (dlouhodobá pracovní neschopnost, ztráta zaměstnání či bydlení, nečekané
        mateřství, apod.) s důsledkem nepokrytí výdajů domácnosti (např. na
        vzdělání dítěte či nákladů na péči o postiženého člena rodiny). Jedná se
        o kompletní služby, nikoliv jen řešení pojistné potřeby.
      </T>
      <T>
        <b>Nadstandardní funkce služby a důležitá upozornění: </b>
        Na rozdíl od finančního poradenství, jehož výsledkem je zpravidla
        doporučení finančního produktu, je cílem této služby návrh celkové
        finanční optimalizace pro případ krizové situace dle preference klienta.
        Poskytování služby nezaručuje úplnou prevenci či eliminaci všech
        krizových scénářů, které volí sám klient, a ani se nejedná o poskytování
        právních/advokátních služeb. Konečné rozhodnutí v případě nastalé
        krizové situace je na volném uvážení klienta a poradce za něj nenese
        odpovědnost.
      </T>
      <T marginTop={2}>
        <b>2.6. Vyřizování agendy za klienta </b>
      </T>
      <T>
        Služba zahrnuje pomoc při veškerém vyřizování agendy na úřadech či vůči
        jiným institucím dle požadavků klienta, tj. např. podání různých
        žádostí, ať už fyzicky či elektronicky.
      </T>
      <T>
        <b>Podstata a rozsah služby "Vyřizování agendy za klienta": </b>
        Služba zahrnuje pomoc při veškerém vyřizování agendy na úřadech či vůči
        jiným institucím (např. tel. operátoři) dle požadavků klienta, tj. např.
        podání různých žádostí, ať už fyzicky či elektronicky. Požadavek po
        domluvě s poradcem zadává klient, který taktéž případně musí poskytnout
        plnou moc, je-li zapotřebí.
      </T>
      <T>
        <b>Nadstandardní funkce služby a důležitá upozornění: </b>
        Služba zahrnuje pouze vyřizování neprávní agendy na úřadech či u jiných
        institucí (např. u telefonního operátora) dle požadavků klienta.
        Nezahrnuje poskytování právních služeb, právních rad ani právní
        zastupování. Případné udělení plné moci poradci slouží vždy pro
        konkrétní úkon dle požadavku klienta a výhradně pro účely vyřízení
        specifikované neprávní agendy. Poradce si vyhrazuje právo odmítnout
        poskytnutí služby.
      </T>
      <T marginTop={2}>
        <b>2.7. Asistence s řešením pojistných událostí </b>
      </T>
      <T>
        Služba nad rámec běžného zprostředkování pojištění zajišťuje propojení
        klienta s odborným specialistou na likvidaci pojistných událostí, který
        klientovi poskytne individuální podporu a asistenci při administraci,
        vyjednávání a finálním řešení pojistných událostí, operativní
        zastupování před pojišťovnou a pomoc při vyřizování náročných pojistných
        případů, které vyžadují specifický expertní přístup.
      </T>
      <T>
        <b>
          Podstata a rozsah služby "Asistence s řešením pojistných událostí":{" "}
        </b>
        Služba nad rámec běžného zprostředkování pojištění zajišťuje propojení
        klienta s odborným specialistou na likvidaci pojistných událostí, který
        klientovi poskytne individuální podporu a asistenci při administraci,
        vyjednávání a finálním řešení pojistných událostí, operativní
        zastupování před pojišťovnou a pomoc při vyřizování náročných pojistných
        případů, které vyžadují specifický expertní přístup.
      </T>
      <T>
        <b>Nadstandardní funkce služby a důležitá upozornění: </b>
        Nadstandardnost služby zajišťuje zejména odborný likvidátor pojistných
        událostí, za jehož činnost poradce nenese odpovědnost. Každý klient má
        nicméně právo na bezplatnou součinnost poradce, jakož i pojišťovny při
        hlášení a řešení pojistných událostí podle podmínek specifikovaných v
        pojistné smlouvě a je tak na svobodném rozhodnutí klienta, zda bude za
        poplatek tuto službu využívat.
      </T>
      <T marginTop={2}>
        <b>2.8. Služby optimalizace výdajů domácnosti </b>
      </T>
      <T>
        Obsahem služby je audit výdajů na chod domácnosti, především
        pravidelných, a doporučení, jak tyto optimalizovat.
      </T>
      <T>
        <b>
          Podstata a rozsah služby "Služby optimalizace výdajů domácnosti":{" "}
        </b>
        Služba zahrnuje průběžné sledování výdajů klienta a dle aktuální
        finanční situace klienta specifická doporučení ke snížení výdajů, ať už
        jednotlivce či celé rodiny, což může podpořit celkovou finanční bonitu
        klienta a napomoci mu získat např. spotřebitelský úvěr.
      </T>
      <T>
        <b>Nadstandardní funkce služby a důležitá upozornění: </b>
        Služba přesahuje zákonnou povinnost finančních poradců zjistit a ověřit
        výdaje před poskytnutím odborného doporučení zejména uzavřít úvěrový
        produkt, kdy se jedná o jednorázovou analýzu výdajové stránky rozpočtu
        klienta, a to aniž by byla vydána související doporučení k jejich
        optimalizaci. Nadto v rámci této služby probíhá tato optimalizace dle
        volby klienta ve vztahu k jednotlivci či celé rodině klienta. Konečné
        rozhodnutí o realizaci doporučení je vždy na klientovi a poradce nenese
        odpovědnost za výsledek takového rozhodnutí ani za případnou škodu.
      </T>
      <T marginTop={2}>
        <b>2.9. Poradce na telefonu </b>
      </T>
      <T>
        Služba garantuje dostupnost finančního poradce na telefonu kdykoli (i
        mimo běžnou pracovní dobu).
      </T>
      <T>
        <b>Podstata a rozsah služby "Poradce na telefonu” </b>
        Služba garantuje dostupnost finančního poradce na telefonu kdykoli (i
        mimo běžnou pracovní dobu). Klient se tak může na poradce obrátit v
        nestandardní dobu např. z důvodu pobytu v jiném časovém pásmu. Výhodou
        je i garance dostupnosti poradce, kterou poradce nemůže vzhledem ke svým
        pracovním povinnostem zaručit ani během běžné pracovní doby. V případě
        nedostupnosti poradce informuje poradce klienta o své nedostupnosti a
        dle svých možností zařídí poradce náhradní osobu, o které bude klienta
        včas informovat.
      </T>
      <T>
        <b>Nadstandardní funkce služby a důležitá upozornění: </b>
        Nadstandardní dostupnost poradce na telefonu neznamená přednostní
        vyřízení požadavku klienta už proto, že v nestandardní dobu nemůže
        poradce zaručit, že se sám okamžitě spojí např. s producentem.
      </T>
      <T marginTop={2}>
        <b>2.10. Přednostní odbavení e-mailových požadavků </b>
      </T>
      <T>
        Služba zajišťuje, že poradce zaznamená a začne řešit požadavky klienta
        do 1 pracovního dne od chvíle, kdy obdrží jeho konkrétní požadavek
        e-mailem, což ocení klienti, kteří z jakéhokoliv důvodu upřednostní
        email před např. telefonátem.
      </T>
      <T>
        <b>
          Podstata a rozsah služby "Přednostní odbavení e-mailových požadavků”{" "}
        </b>
        Služba představuje záruku potvrzení přijetí emailového požadavku klienta
        a informaci o dalším postupu poradce.
      </T>
      <T>
        <b>Nadstandardní funkce služby a důležitá upozornění: </b>
        Nadstandardní přístup k emailovým požadavkům klienta nezakládá právo
        klienta na okamžité vyřešení věci do 1 pracovního dne. Vyřešení
        požadavku se odvíjí od jeho složitosti, jakož i čase jeho přijetí (v
        době pracovního klidu není v moci poradce jeho okamžité vyřešení zejména
        z důvodu nedostupnosti producentů).
      </T>
      <T marginTop={2}>
        <b>2.11. Garance EUCS </b>
      </T>
      <T>
        Klient má právo na zajištění bezplatného zastoupení společností EUCS a s
        ní spolupracující advokátní kanceláří / advokátem při vzniku pojistné
        události ze životního a úrazového pojištění, havarijního pojištění,
        pojištění domácnosti a pojištění nemovitosti. Klient má rovněž právo na
        zajištění bezplatné konzultace nároků z jiných oblastí pojištění se
        zvýhodněnými podmínkami případného zastoupení pro řešení nezaviněných
        dopravních nehod a pracovních úrazů.
      </T>
      <T>
        <b>Podstata a rozsah služby "Garance EUCS” </b>
        Klient má právo na zajištění bezplatného zastoupení společností EUCS a s
        ní spolupracující advokátní kanceláří/advokátem při vzniku pojistné
        události ze životního a úrazového pojištění, havarijního pojištění,
        pojištění domácnosti a pojištění nemovitosti. Klient má rovněž právo na
        zajištění bezplatné konzultace nároků z jiných oblastí pojištění se
        zvýhodněnými podmínkami případného zastoupení pro řešení nezaviněných
        dopravních nehod a pracovních úrazů. Služba Garance EUCS je poskytována
        formou zprostředkování smlouvy mezi klientem a EUCS. Klient má v rámci
        služby k dispozici dokumentaci obsahující veškeré potřebné informace k
        produktu EUCS. Uzavřením Smlouvy, jejíž předmětem je služba EUCS, je
        uzavřena smlouva i přímo s EUCS, přičemž EUCS poté kontaktuje klienta s
        informacemi o smluvním vztahu. Ukončením Smlouvy, jejímž předmětem je
        Garance EUCS, je také ukončením služeb EUCS (není třeba ukončovat
        zvlášť).
      </T>
      <T>
        <b>Nadstandardní funkce služby a důležitá upozornění: </b>V rámci služby
        má klient jistotu, že se mu dostane, dle jeho potřeby, a to i opakovaně,
        zastoupení nejen ze strany EUCS, ale i advokátní kanceláře. Poradce
        nenese odpovědnost za kvalitu takto poskytnutých služeb, neboť je pouze
        zprostředkovává. Konkrétní podmínky poskytování služby se řídí
        podmínkami EUCS.
      </T>
      <T marginTop={2}>
        <b>2.12. Pomoc s rozvodem </b>
      </T>
      <T>
        Poradce poskytuje klientovi informace o finančních důsledcích
        rozvodového řízení a o možných řešeních, která mohou odvrátit jeho
        negativní důsledky, a to i ve spojitosti s finančními produkty, které
        poradce nemá ve správě. Může se jednat např. o informace o
        restrukturalizaci hypotečního úvěru a propojení s hypotečním centrem,
        ale i o propojení s příslušnou advokátní kanceláří tak, aby klient při
        těžké rozvodové situaci získal nadstandardní oporu.
      </T>
      <T>
        <b>Podstata a rozsah služby "Pomoc s rozvodem” </b>
        Poradce poskytuje klientovi informace o finančních důsledcích
        rozvodového řízení a o možných řešeních, která mohou odvrátit jeho
        negativní důsledky. Může se jednat např. o informace o restrukturalizaci
        hypotečního úvěru a propojení s hypotečním centrem, ale i o propojení s
        příslušnou advokátní kanceláří tak, aby klient při těžké rozvodové
        situaci získal nadstandardní oporu. Pro posouzení však poradce potřebuje
        znát veškeré potřebné údaje ze strany klienta včetně informací o
        společném jmění manželů. V případě nedostatečných či neúplných informací
        ze strany klienta nemůže poradce službu řádně poskytnout.
      </T>
      <T>
        <b>Nadstandardní funkce služby a důležitá upozornění: </b>
        Poradce shora uvedenou podporu klientovi poskytuje i ve spojitosti s
        finančními produkty, které nemá ve správě. Služba nepředstavuje finanční
        poradenství ve smyslu doporučení konkrétních finančních produktů, které
        je součástí standardní zprostředkovatelské činnosti finančních služeb.
        Jakékoliv právní aspekty jsou řešeny výhradně prostřednictvím
        spolupracujících advokátních kanceláří. Veškerá konečná rozhodnutí činí
        klient na základě konzultací se svým advokátem. Poradce nenese
        odpovědnost za výsledky rozvodového řízení ani jednání třetích stran.
      </T>
      <T marginTop={2}>
        <b>2.13. Asistence s daňovým přiznáním </b>
      </T>
      <T>
        Nabízíme pomoc při shromažďování a poskytování podkladů a kalkulací
        nezbytných pro vypracování a podání daňového přiznání.
      </T>
      <T>
        <b>Podstata a rozsah služby "Asistence s daňovým přiznáním” </b>
        Klient má právo na zajištění pomoci při jednorázovém či průběžném
        shromažďování a poskytování podkladů a kalkulací nezbytných pro
        vypracování a podání daňového přiznání. Jedná se tedy zejména o
        administrativní činnost poradce.
      </T>
      <T>
        <b>Nadstandardní funkce služby a důležitá upozornění: </b>
        Služba představuje nadstandardní administrativní podporu, která může
        napomoci hladšímu podání daňového přiznání. Klient však bere na vědomí,
        že se nejedná o daňové poradenství a poradce nenese odpovědnost za
        správnost a bezvadnost daňového přiznání.
      </T>
      <T marginTop={2}>
        <b>2.14. Komplexní audit finančního zabezpečení </b>
      </T>
      <T>
        Zaměřujeme se na strategie, které pomohou maximalizovat veškeré Vaše
        důchodové příjmy, které představují pravidelné příjmy, které obdržíte
        jak od státu (např. státní důchody a příspěvky), tak i z jiných zdrojů,
        jako jsou příjmy z pronájmu nemovitosti či investic. Poskytneme
        pravidelnou kontrolu Vašeho sociálního zabezpečení, včetně orientačního
        výpočtu nároku na různé státní důchody. V rámci služby také zajišťujeme,
        abyste měli přehled o všech možnostech, které Vám nabízí systém
        sociálního zabezpečení.
      </T>
      <T>
        <b>
          Podstata a rozsah služby "Komplexní audit finančního zabezpečení”{" "}
        </b>
        Klientovi bude vypracována strategie, která může napomoci k maximalizaci
        důchodových příjmů, které zahrnují všechny finanční prostředky, které
        klient může pravidelně pobírat jak od státu (např. státní důchody a
        příspěvky), tak i z jiných zdrojů, jako jsou příjmy z pronájmu
        nemovitosti či investic nebo penzijních produktů. Předmětem služby je
        také kontrola možností sociálního zabezpečení na základě žádosti
        klienta, vč. orientačního výpočtu nároku na různé státní důchody, ale
        také možností, které pro klienta vyplývají z jeho osobních majetkových
        poměrů (např. výplata dividend, důchodové produkty typu doplňkové
        penzijní spoření).
      </T>
      <T>
        <b>Nadstandardní funkce služby a důležitá upozornění: </b>V rámci služby
        jsou nadstandardně posuzovány majetkové poměry klienta či celé jeho
        rodiny za účelem maximalizace pravidelných příjmů do budoucna. Klient
        však bere na vědomí, že poradce nenese odpovědnost za rozhodnutí orgánů
        státní správy sociálního zabezpečení, ani za další okolnosti, které
        mohou mít ve výsledku vliv na skutečný stav klientových důchodových
        příjmů. Klient také bere na vědomí, že poradce v rámci služby
        neposkytuje právní ani daňové poradenství a pokud se klient na základě
        této služby rozhodne pro sjednání určitého finančního produktu či jeho
        úpravu, činí tak zcela svobodně bez odpovědnosti daného poradce.
      </T>
      <T marginTop={2}>
        <b>2.15. Reality </b>
      </T>
      <T>
        Poradce propojí klienta s realitními odborníky na trhu, kteří mu
        poskytnou bezplatnou konzultaci dle zadání klienta za účelem strategické
        podpory klienta ve sledování jeho cílů na realitním trhu. Klient tak
        může získat např. cenné informace o předpokládaném vývoji tržní hodnoty
        nemovitostí a uzpůsobit tak svá obchodní rozhodnutí. Klienta umíme
        propojit i s odhadci nemovitostí.
      </T>
      <T>
        <b>Podstata a rozsah služby "Reality” </b>
        Předmětem služby je propojení klienta s odborníky z realitního trhu,
        např. realitními makléři či odhadci nemovitostí, kteří mu poskytnou
        konkrétní službu, tedy zejména konzultaci či informace anebo odhad
        předpokládané ceny nemovitosti, aniž by se klient zavázal k jakémukoli
        obchodu (např. koupi nemovitosti).
      </T>
      <T>
        <b>Nadstandardní funkce služby a důležitá upozornění: </b>
        Služba pro klienta zajišťuje nadstandardní poradenskou a konzultační
        podporu za využití know how a kontaktů poradce. Klient nicméně bere na
        vědomí, že se nejedná o realitní činnost poradce, ani o právní,
        advokátní, daňové či obdobné služby poradce. Poradce klientovi pouze
        zprostředkovává kontakt na příslušné odborníky dle poptávky klienta a
        nenese odpovědnost za kvalitu poskytnutých služeb z jejich strany, ani
        za konečná rozhodnutí klienta.
      </T>
      <T marginTop={2}>
        <b>2.16. Energetické poradenství </b>
      </T>
      <T>
        Služba obsahuje posouzení stávající faktury/ceny, kterou klient platí
        dnes a zjištění, zda lze na trhu zajistit lepší podmínky. Poradce může
        klientovi pomoci sjednat výhodnějšího dodavatele.
      </T>
      <T>
        <b>Podstata a rozsah služby "Energetické poradenství” </b>
        Předmětem služby je posouzení výdajů klienta a jeho domácnosti na
        energie jako jsou elektřina či plyn a následná analýza, zda lze tyto
        výdaje optimalizovat. Poradce může také klientovi doporučit vhodnějšího
        dodavatele či navrhnout jiná řešení pro snížení daných výdajů
        domácnosti.
      </T>
      <T>
        <b>Nadstandardní funkce služby a důležitá upozornění: </b>
        Nadstandardnost služby spočívá v kompletní revizi všech výdajů
        domácnosti na energie a díky nadstandardním kontaktům a znalostem
        poradce případný návrh na jejich snížení. Tato služba nabízí oproti{" "}
        <i>Službám optimalizace výdajů domácnosti</i>
        konkrétní zaměření na výdaje na energie, a to nikoliv jen zhodnocení
        celkové ceny, ale taktéž i odebraného množství a jednotkové ceny, kdy
        poté dochází v závislosti na tomto posouzení k vyhodnocení celkové
        výhodnosti a případného doporučení úpravy. Obdobně platí, že má-li
        klient zájem na tom, aby za něj poradce u dodavatelů jeho jménem cokoli
        zajistil, může k tomu být zapotřebí plná moc klienta a může pro něj být
        současně i vhodná služba <i>Vyřizování agendy v zastoupení klienta</i>.
        Klient také bere na vědomí, že poradce nemá odpovědnost za kvalitu
        poskytovaných služeb ze strany jím doporučených nových dodavatelů
        energií.
      </T>
      <T marginTop={2}>
        <b>2.17. Prověření smluv na jiné než finanční produkty </b>
      </T>
      <T>
        Posouzení různých typů smluv (např. rezervačních, kupních), jejichž
        předmětem nejsou finanční produkty, a jejich dopad na finanční situaci
        klienta z pohledu finančního poradce. Jedná se o vysvětlení finančních a
        daňových dopadů smlouvy.
      </T>
      <T>
        <b>
          Podstata a rozsah služby "Prověření smluv na jiné než finanční
          produkty”{" "}
        </b>
        Předmětem služby je posouzení různých typů smluv, které se netýkají
        finančních produktů a jejich následná analýza s cílem detekovat dopad na
        finanční situaci klienta.
      </T>
      <T>
        <b>Nadstandardní funkce služby a důležitá upozornění: </b>
        Podstatou nadstandardnosti služby je poskytnout klientovi komplexní
        poradenství týkající se případných dopadů plynoucích ze smlouvy a
        vysvětlit mu dopady odběru různých služeb a produktů na jeho finanční a
        případně i daňovou situaci. Klient bere na vědomí, že se nejedná o
        finanční zprostředkování či poradenství, ani o daňové, právní či obdobné
        poradenství a poradce nenese odpovědnost za konečná rozhodnutí klienta,
        která na základě této služby učiní. Poradce taktéž ani neodpovídá za
        výslednou formální a obsahovou podobu prověřované smlouvy.
      </T>
      <T marginTop={2}>
        <b>2.18. Průběžné finanční plánování </b>
      </T>
      <T>
        Předmětem služby je činnost poradce, která má pomoci s plněním cílů a
        vizí klienta na základě vypracování a průběžné aktualizace finančního
        plánu. Plán se opírá o analýzu příjmů i výdajů rozpočtu domácnosti a
        její pravidelnou aktualizaci, aniž by se poradce omezoval jen na
        finanční produkty v jeho správě. Poradce navíc podporuje klienta v
        plnění jeho cílů a tím i zdravém řízení financí domácnosti
        prostřednictvím jeho finančního vzdělávání šitém na míru. Klient tak s
        poradcem pravidelně spolupracuje a sleduje průběžné naplňování jeho
        konkrétních cílů.
      </T>
      <T>
        <b>Podstata a rozsah služby "Průběžné finanční plánování” </b>
        Služba zajišťuje podporu pro dosažení cílů a vizí klienta dle jeho volby
        na základě vypracování a průběžné aktualizace finančního plánu. Plán
        vychází z komplexní analýzy a průběžného řízení osobních nebo rodinných
        financí, zahrnujících všechny příjmové a výdajové složky, které poradce
        analyzuje jako celek, aniž by se omezoval výhradně na finanční produkty
        v jeho správě. Služba proto zahrnuje průběžnou aktualizaci a revizi
        plánu podle měnících se okolností a potřeb domácnosti klienta a v
        periodicitě dle jeho volby po domluvě s poradcem (obvykle 6 nebo 12
        měsíců), a to nadto spolu s průběžným finančním vzděláváním klienta
        zaměřeným specificky na potřeby dané domácnosti, což klientovi pomůže
        lépe porozumět financím a efektivněji je řídit. Poradce se taktéž snaží
        vést klienta v rámci finanční gramotnosti k dosažení jeho cílů.
      </T>
      <T>
        <b>Nadstandardní funkce služby a důležitá upozornění: </b>
        Služba přesahuje jednorázové vypracování plánu v rámci
        zprostředkovatelské činnosti, kdy je takový plán omezen jen na
        jednorázová doporučení směrovanému konkrétnímu klientovi (nikoli nutně i
        dalších členů domácnosti) sjednat určité finanční produkty, a to aniž by
        klient byl i průběžně finančně vzděláván poradcem. Poradce se v rámci
        služby především snaží vést klienta k dosažení jeho cílů. Poradce proto
        průběžně sleduje naplňování cílů, a to mimo jiné i zda naplňují
        očekávání klienta. Klient bere na vědomí, že se nejedná o finanční
        zprostředkování či poradenství a předmětem služby tak není doporučení
        uzavřít konkrétní finanční produkt. Stejně tak se nejedná o daňové,
        právní či obdobné poradenství a poradce nenese odpovědnost za konečná
        rozhodnutí klienta, která na základě této služby učiní.
      </T>
      <T>
        3. Co se týče lhůty pro plnění služeb objednaných klientem platí, že
        poradce s klientem si stanovují lhůty individuálně. Takto vznikne časový
        harmonogram pro poskytování služeb. Poradce tento časový harmonogram pro
        poskytování služeb poskytne klientovi na základě jeho žádosti v písemné
        podobě (postačuje formou emailu). V případě, že poradce harmonogram
        nedodrží bez objektivního důvodu, jedná se o porušení povinností
        poradce.
      </T>
      <T>
        4. Ujedná-li si poradce s klientem služby mimo platformu SAB, jedná se o
        separátní smlouvu.
      </T>
      <T marginTop={2}>
        <b>V. Mlčenlivost, ochrana osobních údajů </b>
      </T>
      <T>
        1. Poradce se zavazuje zachovávat mlčenlivost o všech skutečnostech
        týkajících se majetkových, osobních a jiných poměrů klienta, o kterých
        se při plnění svých závazků vyplývajících ze Smlouvy dozvěděl, jakož i
        dodržovat povinnost mlčenlivosti dle příslušných právních norem. Poradce
        se zavazuje nevyužívat tyto informace ve svém vlastním podnikání bez
        předchozího souhlasu klienta.
      </T>
      <T>
        2. Poradce je oprávněn v případě, kdy je to třeba pro výkon činnosti dle
        Smlouvy, poskytovat výše uvedené informace osobám spolupracujícím s
        poradcem. Tímto ustanovením není dotčena povinnost poradce poskytnout
        tyto informace soudu, úřadům nebo jiným orgánům v případech, kdy tak
        stanoví zákon.
      </T>
      <T>
        3. Poradce zpracovává jako správce osobní údaje klienta – fyzické osoby
        zejména za účelem plnění Smlouvy. Rozsah a účel zpracování osobních
        údajů, informace o příjemcích osobních údajů, dobu zpracování osobních
        údajů, poučení o právech klienta jako subjektu údajů stanoví informační
        dokument ((
        <UnstyledLink
          to={getPath(AppRouting.ClientZoneGDPR, token, isAdvisorDetail)}
          target="_blank"
        >
          <Typography component={"span"}>
            odkaz na zásady zpracování OÚ
          </Typography>
        </UnstyledLink>
        ), se kterým se klient seznámil a který akceptoval klient před podpisem
        Smlouvy.
      </T>
      <T>
        4. Poradce tímto výslovně informuje klienta, že osobní údaje klienta
        jsou zpracovávány prostřednictvím SAB a taktéž mohou být uloženy na
        úložištích SAB, neboť služby je poskytována na základě technické
        platformy SAB.
      </T>
      <T marginTop={2}>
        <b>VI. Ukončení Smlouvy </b>
      </T>
      <T>1. Smlouva se uzavírá se na dobu neurčitou.</T>
      <T>
        2. Smlouvu lze ukončit písemnou dohodou, písemným odstoupením od Smlouvy
        nebo písemnou výpovědí.
      </T>
      <T>
        3. Smluvní strany jsou oprávněny od Smlouvy odstoupit v případě
        podstatného porušení této Smlouvy, prohlášení úpadku či likvidace druhé
        smluvní strany.
      </T>
      <T>
        4. Klient, který je spotřebitelem, je oprávněn od Smlouvy odstoupit do
        14 dnů od jejího uzavření, a to bez uvedení důvodu. Za okamžik uzavření
        Smlouvy se považuje okamžik přijetí potvrzení objednávky klienta ve
        smyslu čl. II. odst. 6 VOP. Lhůta je považována za zachovanou, je-li
        odstoupení odesláno nejpozději v poslední den lhůty. K odstoupení klient
        může využít formulář poradce, avšak není to podmínkou platnosti
        odstoupení. Odstoupení není možné v případě dle čl. II odst. 6 VOP.
      </T>
      <T>
        5. Klient je v případě navýšení ceny služeb o DPH dle čl. III. odst. 2
        oprávněn od Smlouvy odstoupit do 14 dnů od obdržení informace o navýšení
        ceny od poradce, přičemž v tomto případě nemá povinnost hradit za
        započaté období zvýšenou cenu.
      </T>
      <T>
        6. Obě smluvní strany jsou oprávněny Smlouvu vypovědět bez uvedení
        důvodu. Výpovědní lhůta činí 1 kalendářní měsíc a počíná běžet prvním
        dnem měsíce následujícího po měsíci, ve kterém došlo k doručení písemné
        výpovědi druhé straně.
      </T>
      <T>
        7. Došlo-li k ukončení Smlouvy, je klient povinen poradci zaplatit již
        poskytnutou službu podle Smlouvy.
      </T>
      <T>
        8. V případě, kdy smluvní strana učiní výpověď Smlouvy či od Smlouvy
        odstoupí, a neučiní-li tak vůči jednotlivé Smlouvě, platí, že se toto
        jednání považuje za jednání směřující k ukončení rámcového vztahu jako
        celku (tedy všech případných dílčích smluv).
      </T>
      <T marginTop={2}>
        <b>VI. Závěrečná ustanovení </b>
      </T>
      <T>
        1. Obchodní podmínky má právo poradce přiměřeně jednostranně změnit a o
        změně je povinen vždy písemně informovat klienta, a to nejméně 2 měsíce
        před navrhovanou změnou. V případě nesouhlasu se změnou má právo klient
        do sedmi dnů od doručení návrhu změny odstoupit od Smlouvy.
      </T>
      <T>
        2. Písemnost odeslaná poštou se považuje v pochybnostech za doručenou
        pátým pracovním dnem po odeslání na adresu smluvní strany uvedenou v
        objednávce nebo na adresu smluvní strany zapsanou ve veřejném rejstříku.
        E-mail se považuje za doručený dnem odeslání.
      </T>
      <T>3. Písemná forma je zachována i v případě komunikace e-mailem.</T>
      <T>
        4. Odpověď na návrh Smlouvy, jež obsahuje jakýkoliv dodatek nebo
        odchylku od původního návrhu, není přijetím nabídky.
      </T>
      <T>5. K řešení sporů ze Smlouvy je příslušný obecný soud poradce.</T>
      <T>
        6. Klient se v případě své nespokojenosti může obrátit na orgán
        mimosoudního řešení spotřebitelských sporů, kterým je Česká obchodní
        inspekce, web: <a href="http://www.coi.cz">www.coi.cz</a>. Více
        informací o procesu mimosoudního řešení je na uvedené webové adrese.
      </T>
    </StyledContentWrapper>
  );
};
