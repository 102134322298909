import { Divider } from "@mui/material";
import { InvoiceCompany } from "Components/Settings/Invoices/InvoiceCompany";
import { UserIssuedInvoicesList } from "Components/Settings/Invoices/UserIssuedInvoicesList";
import { UserReceivedInvoicesList } from "Components/Settings/Invoices/UserReceivedInvoicesList";

export const Invoices: React.FunctionComponent = _ => {
  return (
    <>
      <InvoiceCompany />

      <Divider sx={{ marginTop: 3, marginBottom: 2 }} />

      <UserIssuedInvoicesList />

      <Divider sx={{ marginTop: 3, marginBottom: 2 }} />

      <UserReceivedInvoicesList />
    </>
  );
};
