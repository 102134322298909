import { createSlice } from "@reduxjs/toolkit";
import { getCutoffsPublicIDInvoiceForUserBySupplierCompanyISDOCPdfs } from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { Resources, getTranslation } from "Translations/Resources";
import { downloadBase64File } from "Utils/FileUtils";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const getCutoffInvoiceForUserBySupplierCompanyPdfsAsync =
  createAsyncAction(
    "@cutoff/GET_INVOICE_FOR_USER_BY_SUPPLIER_COMPANY_PDFS_REQUEST",
    "@cutoff/GET_INVOICE_FOR_USER_BY_SUPPLIER_COMPANY_PDFS_RESPONSE",
    "@cutoff/GET_INVOICE_FOR_USER_BY_SUPPLIER_COMPANY_PDFS_FAILURE",
  )<{ cutoffPublicID: string; period: string }, void, Error>();

function* getCutoffInvoiceForUserBySupplierCompanyPdfs(
  action: ReturnType<
    typeof getCutoffInvoiceForUserBySupplierCompanyPdfsAsync.request
  >,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getCutoffsPublicIDInvoiceForUserBySupplierCompanyISDOCPdfs,
      action.payload.cutoffPublicID,
    );

    if (status === 200) {
      downloadBase64File(
        data.isdocPdfsZipBase64,
        "application/zip",
        `${getTranslation(Resources.Settings.Cutoff.Title)} ${
          action.payload.period
        }.zip`,
      );
      yield put(getCutoffInvoiceForUserBySupplierCompanyPdfsAsync.success());
      return;
    }

    yield put(
      getCutoffInvoiceForUserBySupplierCompanyPdfsAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(
      getCutoffInvoiceForUserBySupplierCompanyPdfsAsync.failure(err as Error),
    );
  }
}
export function* getCutoffInvoiceForUserBySupplierCompanyPdfsSaga() {
  yield takeLatest(
    getType(getCutoffInvoiceForUserBySupplierCompanyPdfsAsync.request),
    getCutoffInvoiceForUserBySupplierCompanyPdfs,
  );
}

type GetCutoffInvoiceForUserBySupplierCompanyPdfsSliceState = {
  cutoffPublicID: string | null;
  isLoading: boolean;
  error: Error | null;
};

const initialState: GetCutoffInvoiceForUserBySupplierCompanyPdfsSliceState = {
  cutoffPublicID: null,
  isLoading: false,
  error: null,
};

export const getCutoffInvoiceForUserBySupplierCompanyPdfsSlice = createSlice({
  initialState,
  name: "getCutoffInvoiceForUserBySupplierCompanyPdfs",
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(getCutoffInvoiceForUserBySupplierCompanyPdfsAsync.request),
      (
        state,
        action: ReturnType<
          typeof getCutoffInvoiceForUserBySupplierCompanyPdfsAsync.request
        >,
      ) => {
        state.cutoffPublicID = action.payload.cutoffPublicID;
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(getCutoffInvoiceForUserBySupplierCompanyPdfsAsync.success),
      (
        state,
        action: ReturnType<
          typeof getCutoffInvoiceForUserBySupplierCompanyPdfsAsync.success
        >,
      ) => {
        state.cutoffPublicID = null;
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(getCutoffInvoiceForUserBySupplierCompanyPdfsAsync.failure),
      (
        state,
        action: ReturnType<
          typeof getCutoffInvoiceForUserBySupplierCompanyPdfsAsync.failure
        >,
      ) => {
        state.cutoffPublicID = null;
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});
