import {
  EmailVerificationCommandResult,
  getInvoicesUserReceivedInvoicesISDOCPdfs,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { downloadBase64File } from "Utils/FileUtils";
import { Resources, getTranslation } from "Translations/Resources";
import { formatDate } from "Utils/DateUtils";

export const getAllUserReceivedInvoicePdfsAsync = createAsyncAction(
  "@invoice/GET_ALL_RECEIVED_REQUEST",
  "@invoice/GET_ALL_RECEIVED_RESPONSE",
  "@invoice/GET_ALL_RECEIVED_FAILURE",
)<void, void, Error>();

function* getAllUserReceivedInvoicePdfs(
  action: ReturnType<typeof getAllUserReceivedInvoicePdfsAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getInvoicesUserReceivedInvoicesISDOCPdfs,
    );

    if (status === 200) {
      downloadBase64File(
        data.isdocPdfsZipBase64,
        "application/zip",
        `${getTranslation(
          Resources.Settings.Invoices.InvoicesList.DownloadAll.File,
        )}_${formatDate(new Date(), true, true)}.zip`,
      );
      yield put(getAllUserReceivedInvoicePdfsAsync.success());
      return;
    }

    yield put(
      getAllUserReceivedInvoicePdfsAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(getAllUserReceivedInvoicePdfsAsync.failure(err as Error));
  }
}
export function* getAllUserReceivedInvoicePdfsSaga() {
  yield takeLatest(
    getType(getAllUserReceivedInvoicePdfsAsync.request),
    getAllUserReceivedInvoicePdfs,
  );
}

export const getAllUserReceivedInvoicePdfsSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EmailVerificationCommandResult>(false),
  name: "@invoice/getAllUserReceivedInvoicePdfs",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EmailVerificationCommandResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getAllUserReceivedInvoicePdfsAsync, builder);
    handleSuccessCase(getAllUserReceivedInvoicePdfsAsync, builder);
    handleFailureCase(getAllUserReceivedInvoicePdfsAsync, builder);
  },
});

export const { resetSlice } = getAllUserReceivedInvoicePdfsSlice.actions;
