import { combineReducers } from "@reduxjs/toolkit";
import {
  getAllUserIssuedInvoicePdfsSaga,
  getAllUserIssuedInvoicePdfsSlice,
} from "State/Invoice/GetAllIUserIssuedInvoicePdfsSlice";
import {
  getAllUserReceivedInvoicePdfsSaga,
  getAllUserReceivedInvoicePdfsSlice,
} from "State/Invoice/GetAllIUserReceivedInvoicePdfsSlice";
import {
  getInvoicePdfSlice,
  getInvoicePdfSaga,
} from "State/Invoice/GetInvoicePdf";
import {
  getInvoiceXmlSaga,
  getInvoiceXmlSlice,
} from "State/Invoice/GetInvoiceXml";
import {
  getUserIssuedInvoicesSaga,
  getUserIssuedInvoicesSlice,
} from "State/Invoice/GetUserIssuedInvoicesSlice";
import {
  getUserReceivedInvoicesSaga,
  getUserReceivedInvoicesSlice,
} from "State/Invoice/GetUserReceivedInvoicesSlice";
import { all } from "typed-redux-saga";
import {
  cancelInvoiceForClientByOrderSaga,
  cancelInvoiceForClientByOrderSlice,
} from "State/Invoice/CancelInvoiceForClientByOrderSlice";

export const invoiceReducer = combineReducers({
  userIssuedInvoices: getUserIssuedInvoicesSlice.reducer,
  userReceivedInvoices: getUserReceivedInvoicesSlice.reducer,
  pdf: getInvoicePdfSlice.reducer,
  xml: getInvoiceXmlSlice.reducer,
  allUserIssuedInvoicesPdf: getAllUserIssuedInvoicePdfsSlice.reducer,
  allUserReceivedInvoicesPdf: getAllUserReceivedInvoicePdfsSlice.reducer,
  cancelInvoiceForClientByOrder: cancelInvoiceForClientByOrderSlice.reducer,
});

export function* watchInvoiceSagas() {
  yield all([
    getUserIssuedInvoicesSaga(),
    getUserReceivedInvoicesSaga(),
    getInvoicePdfSaga(),
    getInvoiceXmlSaga(),
    getAllUserIssuedInvoicePdfsSaga(),
    getAllUserReceivedInvoicePdfsSaga(),
    cancelInvoiceForClientByOrderSaga(),
  ]);
}
