import { Typography } from "@mui/material";
import { AddressType, OrderDto, OrderInvoiceTaxWorkflowType } from "Api/Api";
import { useParty } from "Hooks/Party/useParty";
import { usePartyName } from "Hooks/Party/usePartyName";
import { Resources, useResource } from "Translations/Resources";
import {
  formatAddressMunicipality,
  formatAddressStreet,
} from "Utils/AddressUtils";
import { formatPhoneNumber } from "Utils/PhoneUtils";
import styled from "styled-components";

const StyledWrapper = styled.div`
  background-color: ${props => props.theme.colors.gray};
  border-radius: ${props => props.theme.borderRadius};
  padding: ${props => props.theme.spacing(3, 2.5)};
`;

const StyledText = styled(Typography)`
  color: ${props => props.theme.palette.text.secondary};
  font-weight: 500;
`;

type Props = { order: OrderDto };

export const Provider: React.FunctionComponent<Props> = props => {
  const { order } = props;
  const { t } = useResource();

  const { formatPartyName } = usePartyName();

  const { party: supplierParty } = useParty(order.supplierPartyPublicID);
  const { party: userCompanyParty } = useParty(order.userCompanyPartyPublicID);

  const showParty =
    order.invoiceWorkflowType === OrderInvoiceTaxWorkflowType.V2
      ? userCompanyParty
      : supplierParty;

  const showCompanyAddress = showParty?.addresses?.find(
    e => e.type === AddressType.Permanent,
  );
  return (
    <>
      {!!supplierParty && !!showParty && (
        <StyledWrapper>
          <Typography variant="h3" marginBottom={2}>
            {t(Resources.Orders.Supplier.Title)}
          </Typography>
          <StyledText style={{ fontWeight: 800 }}>
            {formatPartyName(showParty)}
          </StyledText>

          {order.invoiceWorkflowType === OrderInvoiceTaxWorkflowType.V2 && (
            <StyledText>{formatPartyName(supplierParty)}</StyledText>
          )}

          {!!showCompanyAddress && (
            <>
              <StyledText>{formatAddressStreet(showCompanyAddress)}</StyledText>

              <StyledText>
                {formatAddressMunicipality(showCompanyAddress)}
              </StyledText>
            </>
          )}

          <StyledText marginTop={1.5}>
            {formatPhoneNumber(showParty.phone)}
          </StyledText>
          <StyledText>{showParty.email}</StyledText>
        </StyledWrapper>
      )}
    </>
  );
};
