import { Typography } from "@mui/material";
import { PartyDto } from "Api/Api";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { StyledContentWrapper } from "Components/Shared/StyledComponents";
import { formatCompanyWithAddress } from "Utils/PartyUtils";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { styled } from "styled-components";

const T = styled(Typography)``;
type Props = {
  supplierParty: PartyDto | undefined;
  token: string;
  isAdvisorDetail: boolean;
};

export const TermsAndConditionsV1: React.FunctionComponent<Props> = props => {
  const { supplierParty, token, isAdvisorDetail } = props;

  return (
    <StyledContentWrapper $fullPadding>
      <T>I. Úvod</T>
      <T>
        1. Tyto všeobecné obchodní podmínky (dále jen „VOP“) poskytovatele
        placeného poradenství<span> </span>
        {formatCompanyWithAddress(supplierParty)}
        upravují smluvní vztahy mezi poskytovatelem služby placeného poradenství
        (dále jen „poradce“), uvedeným v záhlaví této smlouvy a klientem.
      </T>
      <T>
        2. Tyto VOP jsou součástí smlouvy o poskytování poradenství mezi
        poradcem a klientem (dále jen „Smlouva“). Klient se s těmito VOP
        seznámil před uzavřením Smlouvy.
      </T>
      <T marginBottom={2}>3. V případě rozporu má přednost Smlouva před VOP.</T>

      <T>II. Uzavření a předmět Smlouvy</T>
      <T>
        1. Klient kontaktuje poradce s požadavkem na poskytnutí služeb placeného
        poradenství, přičemž dojde k dohodě mezi klientem a poradcem na rozsahu
        a podmínkách těchto služeb. Následně poradce zašle klientovi návrh
        objednávky, v němž je uveden zejména rozsah služeb placeného
        poradenství, jeho cena a platební podmínky.
      </T>
      <T>
        2. V návrhu objednávky vybere poradce pro klienta ty z nabízených služeb
        poradce, o které klient projevil zájem.
      </T>
      <T>
        3. Před odesláním objednávky se klient seznámí s VOP a se zásadami
        zpracování osobních údajů. Klienti jsou také poučeni o právu
        spotřebitele odstoupit od smlouvy a o mimosoudním řešení
        spotřebitelských sporů. Také má klient možnost zvolit, aby poskytovatel
        započal s poskytováním služeb již před uplynutím zákonné lhůty pro
        odstoupení od Smlouvy, přičemž tato volba je relevantní pro klienty z
        řad spotřebitelů.
      </T>
      <T>4. Odesláním je objednávka závazná a neodvolatelná.</T>
      <T>
        5. Smluvní vztah mezi poradcem a klientem je uzavřen v elektronické
        podobě potvrzením objednávky služeb poradce klientem.{" "}
      </T>
      <T>
        6. Potvrzení objednávky bude doručeno klientovi na jím zadanou
        e-mailovou adresu/telefonní číslo.
      </T>
      <T>
        7. Poradce se zavazuje poskytovat ty ze služeb, které jsou uvedeny v
        potvrzené objednávce, a to zejména formou rozborů a analýz situace
        klienta, včetně doporučení dalšího postupu a návrhů řešení, osobních
        konzultací a jednání s klientem a zastupování klienta při jednání s
        třetími osobami, pokud se jedná o věc související s předmětem činnosti
        poradce podle Smlouvy. Předmětem poskytovaných služeb mohou být rovněž
        asistenční a administrativní služby související s finančními produkty
        využívanými klientem.
      </T>
      <T>
        8. Při poskytování služeb podle Smlouvy, zejména při zpracování
        písemností a při jednání s třetími osobami jménem klienta, se poradce
        řídí zadáním a pokyny klienta. Těmito pokyny však není poradce vázán,
        jsou-li v rozporu s právními předpisy.
      </T>
      <T>
        9. Služby jsou poskytovány prostřednictvím následujících osob: členů
        statutárního orgánu poradce, je-li poradce právnickou osobou, jeho
        zaměstnanců a externích spolupracovníků. Klient je oprávněn udílet
        pokyny pouze těm osobám, podílejícím se na poskytování služeb dle
        Smlouvy. Poradce je povinen upozornit klienta na zřejmě nevhodné pokyny,
        příp. pokyny, důsledky jejichž provedení jsou zpravidla spojeny se
        značným rizikem pro klienta.
      </T>
      <T>
        10. Klient se zavazuje poskytnout poradci veškerou potřebnou součinnost
        k provádění činností podle Smlouvy, zejména se mu zavazuje poskytnout
        veškeré potřebné úplné a pravdivé informace a podklady. V případě
        poskytnutí neúplných nebo nepravdivých informací nebo podkladů klientem,
        neodpovídá poradce za škodu, která tím klientovi vznikne.
      </T>
      <T>
        <b>
          11. Předmětem Smlouvy není poskytování žádných investičních služeb
          podle zákona o podnikání na kapitálovém trhu, tedy ani investiční
          služby investiční poradenství týkající se investičních nástrojů, ani
          investiční služby přijímání a předávání pokynů týkajících se
          investičních nástrojů včetně zprostředkování obchodů s investičními
          nástroji. Předmětem Smlouvy není ani zprostředkování pojištění, včetně
          provádění dalších přípravných prací směřujících ke sjednání, změně
          nebo ukončení pojištění, včetně poskytování doporučení vedoucího ke
          sjednání, změně nebo ukončení pojištění, ani zprostředkování
          spotřebitelského úvěru, včetně provádění přípravných prací směřujících
          ke sjednání spotřebitelského úvěru anebo poskytování doporučení
          vedoucího ke sjednání spotřebitelského úvěru. Tyto činnosti poradce
          podle Smlouvy neposkytuje a poskytovat nesmí.
        </b>
      </T>
      <T marginBottom={2}>
        12. Poradce při poskytování služeb podle Smlouvy neodpovídá za
        rozhodnutí klienta a jeho důsledky.
      </T>
      <T>III. Odměna</T>
      <T>
        1. Za služby podle Smlouvy bude klientovi účtována smluvní odměna podle
        dohody smluvních stran, a to buď jako paušální za sjednané období
        (zejména měsíc nebo rok) anebo hodinovou sazbou. Konkrétní výše odměny a
        fakturační období je určeno v potvrzené objednávce klienta.
      </T>
      <T>
        2. V případě, že je poradce plátcem DPH, navýší odměnu o DPH v zákonné
        výši.
      </T>
      <T>
        3. V případě dohody na hodinové sazbě předloží poradce klientovi
        vyúčtování odměny za poskytnuté služby zpravidla jednou měsíčně.
        Vyúčtování bude obsahovat specifikaci úkonů provedených při poskytování
        služeb s uvedením počtu hodin, přičemž doba trvání úkonu se vypočítá s
        přesností na každých začínajících patnáct minut. Nebude-li dohodnuto
        jinak, použije se dohodnutá hodinová sazba i na čas vynaložený v
        souvislosti s poskytnutím služeb podle Smlouvy, např. cesta na jednání
        mimo obec sídla klienta.
      </T>
      <T>
        4. Dohodnutá odměna zahrnuje i náklady na poštovné a náhradu za
        telefonní a faxové poplatky na území ČR.
      </T>
      <T>
        5. Odměna je splatná jedním z následujících způsobů: a) Po poskytnutí
        služby na základě faktury poradce, k níž bude přiloženo vyúčtování podle
        odst. 3 tohoto článku – splatnost faktur se stanoví na 14 dnů ode dne
        doručení faktury klientovi, není-li dohodnuto jinak. b) Předem, když
        objednávka bude obsahovat platební údaje a prohlášení, že zaplacením
        klient projevuje závazně souhlas s objednávkou – daňový doklad je
        klientovi vystaven vždy po připsání platby na účet uvedený v objednávce.
      </T>
      <T marginBottom={2}>
        6. V případě prodlení klienta s úhradou ceny je klient povinen zaplatit
        poradci smluvní pokutu za prodlení se splněním platební povinnosti ve
        výši 0,1 % za každý den prodlení z dlužné částky, se kterou se klient
        ocitne v prodlení. Klient se zavazuje zaplatit takto vzniklou smluvní
        pokutu sedmý pracovní den následující po dni, kdy poradce uplatní tuto
        smluvní pokutu.
      </T>
      <T>IV. Mlčenlivost, ochrana osobních údajů</T>
      <T>
        1. Poradce se zavazuje zachovávat mlčenlivost o všech skutečnostech
        týkajících se majetkových, osobních a jiných poměrů klienta, o kterých
        se při plnění svých závazků vyplývajících ze Smlouvy dozvěděl, jakož i
        dodržovat povinnost mlčenlivosti dle příslušných právních norem. Poradce
        se zavazuje nevyužívat tyto informace ve svém vlastním podnikání bez
        předchozího souhlasu klienta.
      </T>
      <T>
        2. Poradce je oprávněn v případě, kdy je to třeba pro výkon činnosti dle
        Smlouvy, poskytovat výše uvedené informace osobám spolupracujícím s
        poradcem. Tímto ustanovením není dotčena povinnost poradce poskytnout
        tyto informace soudu, úřadům nebo jiným orgánům v případech, kdy tak
        stanoví zákon.
      </T>
      <T marginBottom={2}>
        3. Poradce zpracovává jako správce osobní údaje klienta – fyzické osoby
        zejména za účelem plnění Smlouvy. Rozsah a účel zpracování osobních
        údajů, informace o příjemcích osobních údajů, dobu zpracování osobních
        údajů, poučení o právech klienta jako subjektu údajů stanoví informační
        dokument (
        <UnstyledLink
          to={getPath(AppRouting.ClientZoneGDPR, token, isAdvisorDetail)}
          target="_blank"
        >
          <Typography component={"span"}>
            odkaz na zásady zpracování OÚ
          </Typography>
        </UnstyledLink>
        ), se kterým se klient seznámil a který akceptoval klient před podpisem
        Smlouvy.
      </T>

      <T>V. Ukončení Smlouvy</T>
      <T>1. Smlouva se uzavírá se na dobu neurčitou.</T>
      <T>
        2. Smlouvu lze ukončit písemnou dohodou, písemným odstoupením od
        Smlouvy, písemnou výpovědí.
      </T>
      <T>
        3. Smluvní strany jsou oprávněny od Smlouvy odstoupit v případě
        podstatného porušení této Smlouvy, prohlášení úpadku či likvidace druhé
        smluvní strany.
      </T>
      <T>
        4. Klient, který je spotřebitelem, je oprávněn od Smlouvy odstoupit do
        14 dnů od jejího uzavření, a to bez uvedení důvodu. Lhůta je považována
        za zachovanou, je-li odstoupení odesláno nejpozději v poslední den
        lhůty. K odstoupení klient může využít formulář poradce, avšak není to
        podmínkou platnosti odstoupení.
      </T>
      <T>
        5. Obě smluvní strany jsou oprávněny Smlouvu vypovědět bez uvedení
        důvodu. Výpovědní lhůta činí 1 kalendářní měsíc a počíná běžet prvním
        dnem měsíce následujícího po měsíci, ve kterém došlo k doručení písemné
        výpovědi druhé straně.
      </T>
      <T marginBottom={2}>
        6. Došlo-li k ukončení Smlouvy, je klient povinen poradci zaplatit již
        poskytnutou službu podle Smlouvy.
      </T>

      <T>VI. Závěrečná ustanovení</T>
      <T>
        1. Obchodní podmínky má právo poradce změnit jednostranně, o změně je
        povinen vždy písemně informovat klienta. V případě nesouhlasu se změnou
        má právo klient do sedmi dnů odstoupit od Smlouvy.
      </T>
      <T>
        2. Písemnost odeslaná poštou se považuje v pochybnostech za doručenou
        pátým dnem po odeslání na adresu smluvní strany uvedenou v objednávce
        nebo na adresu smluvní strany zapsanou ve veřejném rejstříku. E-mail se
        považuje za doručený dnem odeslání.
      </T>
      <T>3. Písemná forma je zachována i v případě komunikace e-mailem.</T>
      <T>
        4. Pravidla obsažená v §1765 odst. 1 a §1766 zákona č. 89/2012 Sb.,
        občanský zákoník se neuplatní, což mimo jiné znamená, že pokud dojde ke
        změně okolností tak podstatné, že změna založí v právech a povinnostech
        stran zvlášť hrubý nepoměr znevýhodněním jedné z nich buď neúměrným
        zvýšením nákladů plnění, anebo neúměrným snížením hodnoty předmětu
        plnění, nemá dotčená strana právo domáhat se vůči druhé straně obnovení
        jednání o Smlouvě.
      </T>
      <T>
        5. Odpověď na návrh Smlouvy, jež obsahuje jakýkoliv dodatek nebo
        odchylku od původního návrhu, není přijetím nabídky.
      </T>
      <T>6. K řešení sporů ze Smlouvy je příslušný obecný soud poradce.</T>
    </StyledContentWrapper>
  );
};
