import { BlButton } from "Components/Shared/Buttons/BlButton";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { getAllUserIssuedInvoicePdfsAsync } from "State/Invoice/GetAllIUserIssuedInvoicePdfsSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";

type Props = {
  isDisabled: boolean;
};

export const DownloadAllUserIssuedInvoicesButton: React.FunctionComponent<
  Props
> = props => {
  const { t } = useResource();

  const { isLoading } = useAppSelector(e => e.invoice.allUserIssuedInvoicesPdf);

  const dispatch = useAppDispatch();

  const downloadAll = () => {
    dispatch(getAllUserIssuedInvoicePdfsAsync.request());
  };

  return (
    <>
      <BlButton
        isLoading={isLoading}
        disabled={props.isDisabled}
        onClick={downloadAll}
      >
        {t(Resources.Settings.Invoices.InvoicesList.DownloadAll.Button)}
      </BlButton>
    </>
  );
};
