import { Typography } from "@mui/material";
import { UserSupplierCompanyListItemDto } from "Api/Api";
import { LoadingWrapper } from "Components/Orders/Draft/LoadingWrapper";
import { OrderFormModel } from "Components/Orders/Draft/StepFormValidationSchema";
import { BlDropdown } from "Components/Shared/Inputs/Form/BlDropdown";
import { StyledContentWrapper } from "Components/Shared/StyledComponents";
import { Control, FieldErrors } from "react-hook-form";

type Props = {
  control: Control<OrderFormModel, any>;
  errors: FieldErrors<OrderFormModel>;
  isLoading: boolean;
  companies: UserSupplierCompanyListItemDto[];
  isReadOnly: boolean;
};

export const SupplierFields: React.FunctionComponent<Props> = props => {
  const { control, errors, isLoading, companies, isReadOnly } = props;

  return (
    <StyledContentWrapper>
      <Typography variant="h2" marginBottom={2}>
        Pod kým poskytuji
      </Typography>

      <LoadingWrapper isLoading={isLoading}>
        <BlDropdown
          control={control}
          errors={errors}
          name={"supplierCompanyID"}
          label="Společnost"
          disabled={isReadOnly}
          codeList={companies
            .filter(x => x.canBeSelectedToOrderDisabled === false)
            .map(e => ({
              code: e.companyID,
              name: e.companyName ?? "",
            }))}
        />
      </LoadingWrapper>
    </StyledContentWrapper>
  );
};
