import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";
import {
  EnterpriseServiceListItemDto,
  EnterpriseServiceSettingsDto,
  OrderDto,
  OrderServiceDto,
  PartyDto,
  SaveDraftRequest,
  UserSupplierCompanyListItemDto,
  CompanyDto,
  RelationType,
  ServicePackageListItemDto,
  EnterpriseMode,
} from "Api/Api";
import { ClientFields } from "Components/Orders/Draft/ClientFields";
import { LoadingWrapper } from "Components/Orders/Draft/LoadingWrapper";
import { MissingUserCompanyWarning } from "Components/Orders/Draft/MissingUserCompanyWarning";
import { NoteFields } from "Components/Orders/Draft/NoteFields";
import { PeriodicityFields } from "Components/Orders/Draft/PeriodicityFields";
import { SupplierFields } from "Components/Orders/Draft/SupplierFields";
import { ServicesFields } from "Components/Orders/Draft/ServicesFields";
import {
  FormMode,
  OrderFormModel,
  useOrderStepFormValidationsSchema,
} from "Components/Orders/Draft/StepFormValidationSchema";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import useScrollToError from "Hooks/Form/useScrollToError";
import { Resources, useResource } from "Translations/Resources";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ObjectSchema } from "yup";
import { useNavigate } from "react-router";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { PaymentFields } from "Components/Orders/Draft/PaymentFields";
import { getDefaultValues, map } from "./Utils";
import { BlConfirmButton } from "Components/Shared/Buttons/BlConfirmButton";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { deleteOrderAsync } from "State/Orders/Detail/DeleteOrderSlice";

type Props = {
  order: OrderDto | null;
  isLoading: boolean;
  companies: UserSupplierCompanyListItemDto[];
  enterpriseServices: EnterpriseServiceListItemDto[];
  orderServices: OrderServiceDto[];
  serviceSettings: EnterpriseServiceSettingsDto;
  onSubmit: (mode: FormMode, saveRequest: SaveDraftRequest) => void;
  isSaving: boolean;
  clientParty: PartyDto | null;
  userCompany: CompanyDto | null;
  servicePackages: ServicePackageListItemDto[];
  enterpriseMode: EnterpriseMode;
};

export const DraftForm: React.FunctionComponent<Props> = props => {
  const { t } = useResource();
  const navigate = useNavigate();
  const {
    isLoading,
    order,
    companies,
    enterpriseServices,
    serviceSettings,
    orderServices,
    isSaving,
    clientParty,
    userCompany,
    servicePackages,
    enterpriseMode,
    onSubmit,
  } = props;
  const dispatch = useAppDispatch();

  const isReadOnly =
    !!order && order?.userRelationType !== RelationType.OrderActions;

  const validationSchema: ObjectSchema<OrderFormModel> =
    useOrderStepFormValidationsSchema(serviceSettings);

  const form = useForm<OrderFormModel>({
    resolver: yupResolver(validationSchema),
    defaultValues: getDefaultValues(
      order,
      companies,
      clientParty,
      enterpriseServices,
      orderServices,
      servicePackages,
    ),
  });
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = form;

  useEffect(() => {
    console.log("errors", errors);
  }, [errors]);

  const submit = async (formMode: FormMode) => {
    setValue("mode", formMode);
    await trigger();
    handleSubmit((formModel: OrderFormModel) => {
      onSubmit(formMode, map(formModel, enterpriseServices, userCompany!));
    })();
  };

  useScrollToError(errors);

  const isSavingDisabled = isSaving || watch("services").some(x => x.isEditing);
  const hasAnySelectedService =
    watch("services").filter(x => x.isSelected)?.length > 0;

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const canDelete =
    !!order &&
    !!order.publicID &&
    order.userRelationType === RelationType.OrderActions;

  const deleteDraft = () => {
    if (!canDelete) {
      return;
    }

    dispatch(deleteOrderAsync.request({ publicID: order.publicID, navigate }));
  };

  return (
    <>
      <LoadingWrapper isLoading={isLoading}>
        <Typography variant="h1" marginBottom={4}>
          {!!order && order.orderID > 0
            ? t(Resources.Orders.Detail.DraftTitle, { number: order.number })
            : t(Resources.Orders.Detail.CreateTitle)}
        </Typography>
      </LoadingWrapper>

      {userCompany === null && <MissingUserCompanyWarning />}

      <form>
        <Box marginBottom={4}>
          <SupplierFields
            companies={companies}
            control={control}
            errors={errors}
            isLoading={isLoading}
            isReadOnly={isReadOnly}
          />
        </Box>

        <Box marginBottom={4}>
          <ClientFields
            form={form}
            isLoading={isLoading}
            isReadOnly={isReadOnly}
          />
        </Box>

        <Box marginBottom={4}>
          <ServicesFields
            form={form}
            isLoading={isLoading}
            enterpriseServices={enterpriseServices}
            serviceSettings={serviceSettings}
            companies={companies}
            isReadOnly={isReadOnly}
            userCompany={userCompany!}
            servicePackages={servicePackages}
            userSupplierCompanies={companies}
            enterpriseMode={enterpriseMode}
          />
        </Box>

        <Box mb={4}>
          <PaymentFields
            form={form}
            isLoading={isLoading}
            isReadOnly={isReadOnly}
            userCompany={userCompany!}
            enterpriseServices={enterpriseServices}
            userSupplierCompanies={companies}
            enterpriseMode={enterpriseMode}
          />
        </Box>

        <Box marginBottom={4}>
          <PeriodicityFields
            form={form}
            isLoading={isLoading}
            isReadOnly={isReadOnly}
          />
        </Box>

        <Box marginBottom={4}>
          <NoteFields
            control={control}
            errors={errors}
            isLoading={isLoading}
            isReadOnly={isReadOnly}
          />
        </Box>
      </form>

      <Grid container gap={2}>
        <Grid item>
          <BlButton
            color="primary"
            onClick={() => submit(FormMode.Process)}
            disabled={
              isSavingDisabled ||
              userCompany === null ||
              isReadOnly ||
              !hasAnySelectedService
            }
          >
            Rekapitulace objednávky
          </BlButton>
        </Grid>
        <Grid item>
          <BlButton
            color="secondary"
            onClick={() =>
              isReadOnly
                ? navigate(getPath(AppRouting.Dashboard))
                : submit(FormMode.Draft)
            }
            disabled={isSavingDisabled}
          >
            {isReadOnly ? "Zavřít" : "Uložit a zavřít"}
          </BlButton>
        </Grid>
        {canDelete && (
          <Grid item>
            <BlConfirmButton
              variant="secondary"
              disabled={isSavingDisabled}
              modalTitle={t(Resources.Orders.Detail.DraftForm.Delete.Title)}
              modalContent={t(
                Resources.Orders.Detail.DraftForm.Delete.Confirmation,
              )}
              onResult={result => {
                if (result) {
                  deleteDraft();
                }
              }}
            >
              {t(Resources.Orders.Detail.DraftForm.Delete.Button)}
            </BlConfirmButton>
          </Grid>
        )}
      </Grid>
    </>
  );
};
