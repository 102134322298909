import { BlButton } from "Components/Shared/Buttons/BlButton";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { getAllUserReceivedInvoicePdfsAsync } from "State/Invoice/GetAllIUserReceivedInvoicePdfsSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";

type Props = {
  isDisabled: boolean;
};

export const DownloadAllUserReceivedInvoicesButton: React.FunctionComponent<
  Props
> = props => {
  const { t } = useResource();

  const { isLoading } = useAppSelector(
    e => e.invoice.allUserReceivedInvoicesPdf,
  );

  const dispatch = useAppDispatch();

  const downloadAll = () => {
    dispatch(getAllUserReceivedInvoicePdfsAsync.request());
  };

  return (
    <>
      <BlButton
        isLoading={isLoading}
        disabled={props.isDisabled}
        onClick={downloadAll}
      >
        {t(Resources.Settings.Invoices.InvoicesList.DownloadAll.Button)}
      </BlButton>
    </>
  );
};
