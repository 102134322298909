import { combineReducers } from "@reduxjs/toolkit";
import {
  downloadTermAndConditionFileSaga,
  downloadTermAndConditionFileSlice,
} from "State/Users/DownloadTermAndConditionFile";
import {
  searchAdvisorUsersInEnterpriseSaga,
  searchAdvisorUsersInEnterpriseSlice,
} from "State/Users/SearchAdvisorUsersInEnterpriseSlice";
import { all } from "typed-redux-saga";

export const userReducer = combineReducers({
  searchAdvisorUsersInEnterprise: searchAdvisorUsersInEnterpriseSlice.reducer,
  downloadTermAndConditionFile: downloadTermAndConditionFileSlice.reducer,
});

export function* watchUserSagas() {
  yield all([
    searchAdvisorUsersInEnterpriseSaga(),
    downloadTermAndConditionFileSaga(),
  ]);
}
